<ol class="breadcrumb">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <li *ngFor="let item of breadcrumbList; let i = index" (click)="clickBreadcrum(item.name)"
                    class="cursor-pointer breadcrumb-li" [class.active]="i===breadcrumbList.length-1">
                    <a *ngIf="i!==breadcrumbList.length-1">
                        {{ item.name | titlecase}}
                    </a>
                    <span class="active text-white " *ngIf="i === breadcrumbList.length-1">{{ item.name |
                        titlecase}}</span>
                </li>
            </div>

            <div class="col-md-6">
                <div class="float-right">
                    <span class="text-right mr-2 span-border-css" (click)="onClickHome()" *ngIf="isShowHome">
                        <label class="text-link text-bold" (click)="onClickHome()">
                            <span><i class="font-14 fa-solid fa-house text-black"></i></span>
                            Home</label>
                    </span>
                    <span class="text-right mr-2 span-border-css" *ngIf="isPunchbuttonvisible">
                        <label class="text-link text-bold" (click)="punchModal(templatep3)" >
                            <span><i class="font-14 fa-solid fa-clock text-black " ></i></span>
                            Punch</label>
                    </span>
                    <span class="text-right mr-2 span-border-css">
                        <label>Language - {{selectedLanguage}}</label>
                    </span>
                    <span class="text-center">
                        <label class="page-title color-fontwight-css mr-1">Last Seen : {{lastLoginDateTime |
                            date:'dd-MMM-yyyy'
                            }}&nbsp;| {{lastLoginDateTime | date:'hh:mm:ss a'}} </label>
                    </span>
                </div>
            </div>

            <!-- <div class="col-md-3 mt-1 text-right">
                <div> -->
            <!-- <h1  class="page-title color-fontwight-css">Last Seen :
                        {{employeeInfo.lastLoginDateTime
                          | date:'dd-MMM-yyyy'}}&nbsp;| {{employeeInfo.lastLoginDateTime | date:'hh:mm:ss a'}}
                    </h1> -->
            <!-- <h1  class="page-title color-fontwight-css">Last Seen :
                      {{lastLoginDateTime | date:'dd-MMM-yyyy'
                        }}&nbsp;| {{lastLoginDateTime | date:'hh:mm:ss a'}}
                  </h1>
                </div>               
            </div> -->
            <!-- <div *ngIf="isShowHome" class="col-md-1 text-right"><label class="form-label text-link text-bold" (click)="onClickHome()">
              <span><i class="font-14 fa-solid fa-house text-black mr-2"
                (click)="onClickHome()"></i></span>
              Home</label></div>  
              <button type="button" tooltip="Punch" (click)="punchModal(templatep3)"
              *ngIf="isPunchbuttonvisible"
              class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left mr-2 margin-top-5"><b><i
                      class="fa-solid fa-clock font-14"></i></b>
              Punch </button>   
             <div class="col-md-2 text-right"> <label>Language - {{selectedLanguage}}</label> </div>         -->
        </div>
    </div>
</ol>
<ng-template #templatep3>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Mark Attendance</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>

    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <button type="button"  [disabled]="!isPunchin"
                                    (click)="onpunchin('Punch In')"
                                    [ngClass]="{'disabled-btn-bg-purple':!isPunchin,'btn-bg-green':isPunchin}"
                                    class="purple-btn custom_button custom_btn  btn-labeled btn-labeled-left wd99"><b><i
                                            class="fa-solid fa-user-clock"></i></b> Punch In
                                </button>
                            </div>
                            <div class="col-md-4">
                                <button type="button"  [disabled]="!ispunchout"
                                    (click)="onpunchin('Punch Out')"
                                    [ngClass]="{'disabled-btn-bg-purple':!ispunchout,'btn-bg-green':ispunchout}"
                                    class="purple-btn custom_button custom_btn btn-labeled btn-labeled-left wd99"><b><i
                                            class="fa-solid fa-person-walking-arrow-right"></i></b>
                                    Punch
                                    Out
                                </button>
                            </div>
                        </div>
                        <div class="row mt-25" *ngIf="breakButton=='Yes'">
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <button type="button" [disabled]="!isbreak || isPunchin" (click)="onpunchin('Break')"
                                    [ngClass]="{'disabled-btn-bg-purple':!isbreak || isPunchin,'btn-bg-green':isbreak || !isPunchin}"
                                    class="purple-btn custom_button custom_btn  btn-labeled btn-labeled-left wd99"><b><i
                                            class="fa-solid fa-mug-hot"></i></b> Break
                                </button>
                            </div>
                            <div class="col-md-4">
                                <button type="button"  [disabled]="!isResume"
                                    (click)="onpunchin('Resume')"
                                    [ngClass]="{'disabled-btn-bg-purple':!isResume || isPunchin || isbreak,'btn-bg-green':isResume || !isPunchin ||isbreak}"
                                    class="purple-btn custom_button custom_btn  btn-labeled btn-labeled-left wd99"><b><i
                                            class="fa-solid fa-user-check"></i></b> Resume
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</ng-template>