
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LoanMasterService } from 'src/app/modules/loan-master/loan-master.service';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { PayrollServiceService } from '../../services/payroll-service.service';
import { ExcelserviceService } from 'src/app/core/services/excelservice.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelpDocumentService } from 'src/app/common-shared/help-document/help-document.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-payroll-area-master',
  templateUrl: './payroll-area-master.component.html',
  styleUrls: ['./payroll-area-master.component.scss'],
})
export class PayrollAreaMasterComponent implements OnInit {
  public payrollAreaForm: FormGroup;
  public summary: any = [];
  public summaryHistory: any = [];
  public modalRef: BsModalRef;
  isShowRemark: boolean = false;
  type: any = {
    category: 'master',
    tableName: 'Payroll Area',
    tableId: 47,
  };
  showHelpDoc: boolean = false;
  businessCycleData: any = '';
  editFlag: boolean = false;
  viewFlag: boolean = false;
  isLoader: boolean = false;
  payrollAreaMasterId: any = '';
  companyData: any;
  phgData: any = '';
  sdmData: any = '';
  frequency: any = '';
  lmsAreaData: any = '';
  currancyData: any = '';
  languageData: any = '';
  sdmDerivedList: any;
  defaultAttendace: number = 0;
  selectedFromDate: Date;
  excelData: any[] = [];
  header: any[] = [];
  selectedToDate: Date;
  // is30daysPayroll: boolean = false;
  expenseReimbursementAreaData: any = '';
  jvGroupData: any = '';
  incometaxTemplateData: any = '';
  paymentTrackingGroupData: any = '';
  paymentTrackingGroupDataTemp: any = '';
  sdmName: any = '';
  selectedPayslipDate: Date;
  selectedTaxsheetDate: Date;

  constructor(
    private service: PayrollServiceService,
    private toaster: AlertServiceService,
    private loanMasterService: LoanMasterService,
    private datepipe: DatePipe,
    private excelservice: ExcelserviceService,
    private modalService: BsModalService,
    private commonPopup: HelpDocumentService
  ) {
    this.getBussinessCycleDetails();
    this.getCompanyDetails();
    this.getphgDetails();
    this.getSdmDetails();
    this.getLmsMasterDetails();
    this.getCurrencyList();
    this.getLanguagesList();
    this.getExpenseReimbursementAreaDetails();
    this.getJVGroupDetails();
    this.getIncomeTaxTemplateDetails();
    this.getPaymentTrackingGroupDetails();
    this.createForm();
  }

  createForm() {
    this.payrollAreaForm = new FormGroup({
      businessCycleDefinitionId: new FormControl('', [Validators.required]),
      payrollAreaCode: new FormControl('', [Validators.required]),
      payrollAreaDescription: new FormControl('', [Validators.required]),
      headGroupDefinitionId: new FormControl('', [Validators.required]),
      currency: new FormControl('₹-INR', [Validators.required]),
      isActive: new FormControl(1),
      effectiveFromDate: new FormControl('', [Validators.required]),
      effectiveToDate: new FormControl({ value: new Date('31-Dec-9999') }, [
        Validators.required,
      ]),
      arraylistCompanyIds: new FormControl('', [Validators.required]),
      defaultAttendace: new FormControl(1),
      defaultLanguage: new FormControl('English'),
      minNetPaySDM: new FormControl(''),
      minNetPayDerivedId: new FormControl(''),
      remark: new FormControl(''),
      createdBy: new FormControl(''),
  //    is30daysPayroll: new FormControl(0),
      isFixedDenominator: new FormControl(0),
      lmsAreaId: new FormControl(''),
      uom: new FormControl('', [Validators.required]),
      areaMasterId: new FormControl(2),
      // denominator30Days: new FormControl(''),
      isFixedDenominatorDays: new FormControl(''),
      expenseReimbursementAreaMasterId: new FormControl(''),
      incomeTaxSettingTemplateName: new FormControl('', [Validators.required]),
      jvGroupName: new FormControl(''),
      paymentTrackingGroupName: new FormControl('', [Validators.required]),
      standardminperDays: new FormControl('', [
        Validators.pattern('^[1-9][0-9]*$'),
        Validators.min(0),Validators.max(60241440),
        Validators.required,
      ]),
      payslipShowFrom: new FormControl('', [Validators.required]),
      taxsheetShowFrom: new FormControl('', [Validators.required]),
    });
    
  }
  ngOnInit() {
    setTimeout(() => {
      let a = true;
      this.getPayrollAreaDetails();
    }, 1200);

    this.getData();
  }

  positiveValueValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const value = control.value;
    if (value !== null && (isNaN(value) || value <= 0)) {
      return { positiveValue: true };
    }
    return null;
  }

  async getData() {
    await this.commonPopup
      .getHelpDocument(this.type)
      .toPromise()
      .then((success) => {
        this.showHelpDoc = success.data.results[0].length > 0 ? true : false;
      })
      .catch((error) => {
        this.showHelpDoc = false;
      });
  }
  getPayrollAreaDetails() {
    this.service.getPayrollAreaDetails().subscribe((res) => {
      this.summary = res.data.results;
      this.summary.forEach((element) => {
        element.effectiveFromDate = new Date(element.effectiveFromDate);
        element.effectiveToDate = new Date(element.effectiveToDate);
       
        if (element.defaultAttendace == 1) {
          element.isDefaultAttendace = 'Yes';
        } else {
          element.isDefaultAttendace = 'No';
        }
        if (element.isActive == 1) {
          element.isActiveT = 'Yes';
        } else {
          element.isActiveT = 'No';
        }
        this.companyData.forEach((com) => {
          if (com.groupCompanyId == element.companyId) {
            element.companyName = com.companyName;
          }
        });
        this.businessCycleData.forEach((cycle) => {
          if (
            parseInt(cycle.id) == parseInt(element.businessCycleDefinitionId)
          ) {
            element.businessCycleDefinitionName = cycle.name;
          }
        });
      });
    });
  }
  getBussinessCycleDetails() {
    this.service.getBussinessCycleDetails().subscribe((res) => {
      this.businessCycleData = res.data.results[0];
    });
  }
  getCompanyDetails() {
    this.service.getCompanyDetails().subscribe((res) => {
      this.companyData = res.data.results;
    });
  }
  getphgDetails() {
    this.service.getPhgDetails().subscribe((res) => {
      this.phgData = res.data.results;
    });
  }
  getSdmDetails() {
    this.service.getSdmDetails().subscribe((res) => {
      this.sdmData = res.data.results;
    });
  }
  getLmsMasterDetails() {
    this.service.getLmsMasterDetails().subscribe((res) => {
      this.lmsAreaData = res.data.results[0];
    });
  }
  getCurrencyList() {
    this.service.getCurrencyList().subscribe((res) => {
      this.currancyData = res.data.results;
    });
  }
  getLanguagesList() {
    this.service.getLanguagesList().subscribe((res) => {
      this.languageData = res.data.results;
    });
  }
  getExpenseReimbursementAreaDetails() {
    this.service.getExpenseReimbursementAreaDetails().subscribe((res) => {
      this.expenseReimbursementAreaData = res.data.results;
    });
  }
  getJVGroupDetails() {
    this.service.getJVGroupDetails().subscribe((res) => {
      this.jvGroupData = res.data.results[0];
    });
  }
  getIncomeTaxTemplateDetails() {
    this.service.getIncomeTaxTemplateDetails().subscribe((res) => {
      this.incometaxTemplateData = res.data.results;
    });
  }
  getPaymentTrackingGroupDetails() {
    this.service.getPaymentTrackingGroupDetails().subscribe((res) => {
      this.paymentTrackingGroupData = res.data.results[0];
      this.paymentTrackingGroupDataTemp = res.data.results[0];
    });
  }
  checkedUnchecked(event) {
    if (event.checked) {
      this.isShowRemark = false;
      this.payrollAreaForm.controls['isActive'].setValue(1);
      this.payrollAreaForm.controls['remark'].clearValidators();
      this.payrollAreaForm.controls['remark'].updateValueAndValidity();
    } else {
      this.isShowRemark = true;
      this.payrollAreaForm.controls['isActive'].setValue(0);
      this.payrollAreaForm.controls['remark'].setValidators([
        Validators.required,
      ]);
      this.payrollAreaForm.controls['remark'].updateValueAndValidity();
    }
  }
  setFromDate(value) {
    this.selectedFromDate = value == null ? null : new Date(value);
    this.payrollAreaForm.controls['effectiveFromDate'].setValue(
      value == null
        ? null
        : this.datepipe.transform(new Date(value), 'yyyy-MM-dd') + ' 00:00:00'
    );
  }
  setToDate(value) {
    if (value !== null) {
      this.selectedToDate = value == null ? null : new Date(value);
      this.payrollAreaForm.controls['effectiveToDate'].setValue(
        value == null
          ? null
          : this.datepipe.transform(new Date(value), 'yyyy-MM-dd') + ' 00:00:00'
      );
    } else {
      this.selectedToDate = new Date('31-Dec-9999')
      return new Date(9999,11,31);
    }
  }

  setPayslipDate(value) {
    this.selectedPayslipDate = value == null ? null : new Date(value);
    this.payrollAreaForm.controls['payslipShowFrom'].setValue(
      value == null
        ? null
        : this.datepipe.transform(new Date(value), 'yyyy-MM-dd') + ' 00:00:00'
    );
  }

  setTaxsheetDate(value) {
    this.selectedTaxsheetDate = value == null ? null : new Date(value);
    this.payrollAreaForm.controls['taxsheetShowFrom'].setValue(
      value == null
        ? null
        : this.datepipe.transform(new Date(value), 'yyyy-MM-dd') + ' 00:00:00'
    );
  }
  onChangeisFixedDenominator(value){
if(value==1){
  this.payrollAreaForm.controls['isFixedDenominatorDays'].setValue("");
  this.payrollAreaForm.controls['isFixedDenominatorDays'].setValidators(Validators.required);
  this.payrollAreaForm.controls['isFixedDenominatorDays'].updateValueAndValidity();
}else{
  this.payrollAreaForm.controls['isFixedDenominatorDays'].setValue("");
  this.payrollAreaForm.controls['isFixedDenominatorDays'].clearValidators();
  this.payrollAreaForm.controls['isFixedDenominatorDays'].updateValueAndValidity();
}
  }
  getAllDerivedSDM(event) {
    let sdmMasterId = '';
    this.sdmDerivedList = [];
    if (event == ''||event==null||event===undefined||event==='null') {
      this.sdmName = event;
      this.payrollAreaForm.controls['minNetPayDerivedId'].setValue('');
      this.payrollAreaForm.controls['minNetPayDerivedId'].clearValidators();
      this.payrollAreaForm.controls[
        'minNetPayDerivedId'
      ].updateValueAndValidity();
    }  else {
      this.payrollAreaForm.controls['minNetPayDerivedId'].setValidators(
        Validators.required
      );
      this.payrollAreaForm.controls[
        'minNetPayDerivedId'
      ].updateValueAndValidity();
    }
    this.sdmData.forEach((element) => {
      if (event == element.sdmName) {
        sdmMasterId = element.sdmMasterId;
        this.service.getAllDerivedSDM(sdmMasterId).subscribe((res) => {
          res.data.results.forEach((element) => {
            if (element.derivedName != 'No Derived')
              this.sdmDerivedList.push(element);
          });
        });
      }
    });
  }
  resetForm() {
    this.payrollAreaForm.reset();
    this.payrollAreaForm.enable();
    this.payrollAreaForm.controls['effectiveToDate'].setValue(
      new Date('31-Dec-9999')
    );
    this.selectedToDate = new Date('31-Dec-9999');
    this.payrollAreaForm.controls['effectiveToDate'].setValue(
      this.datepipe.transform(
        new Date(this.payrollAreaForm.controls['effectiveToDate'].value),
        'yyyy-MM-dd'
      ) + ' 00:00:00'
    );
    this.payrollAreaForm.controls['defaultLanguage'].setValue('English');
    this.payrollAreaForm.controls['defaultAttendace'].setValue(0);
    this.payrollAreaForm.controls['currency'].setValue('₹-INR');
    // this.payrollAreaForm.controls['is30daysPayroll'].setValue(0);
    // this.payrollAreaForm.controls['denominator30Days'].setValue('');
    this.payrollAreaForm.controls['minNetPayDerivedId'].setValue("");
    this.payrollAreaForm.controls['minNetPayDerivedId'].clearValidators();
    this.payrollAreaForm.controls['minNetPayDerivedId'].setValue("");
    this.payrollAreaForm.controls['minNetPayDerivedId'].clearValidators();
    this.payrollAreaForm.controls['isFixedDenominator'].setValue(0);
     this.payrollAreaForm.controls['isFixedDenominatorDays'].setValue("");
    this.payrollAreaForm.controls['areaMasterId'].setValue(2);
    this.payrollAreaForm.controls['remark'].clearValidators();
    this.editFlag = false;
    this.viewFlag = false;
    this.isShowRemark = false;
    this.selectedFromDate = null;
    this.defaultAttendace = 0;
    this.frequency = '';
    this.setFromDate(null);
    this.setPayslipDate(null);
    this.setTaxsheetDate(null);
    this.selectedPayslipDate = null;
    this.selectedTaxsheetDate = null;
    this.payrollAreaForm.updateValueAndValidity();
  }
  submit() {
  
    
    this.payrollAreaForm.controls['arraylistCompanyIds'].setValue(
      this.returnList(),
    );
    this.payrollAreaForm.controls['defaultAttendace'].setValue(
      parseInt(this.payrollAreaForm.controls['defaultAttendace'].value)
    );
    this.payrollAreaForm.controls['minNetPayDerivedId'].setValue(
      parseInt(this.payrollAreaForm.controls['minNetPayDerivedId'].value)
    );
    this.payrollAreaForm.controls['businessCycleDefinitionId'].setValue(
      parseInt(this.payrollAreaForm.controls['businessCycleDefinitionId'].value)
    );
    this.payrollAreaForm.controls['headGroupDefinitionId'].setValue(
      parseInt(this.payrollAreaForm.controls['headGroupDefinitionId'].value)
    );
    this.payrollAreaForm.controls['lmsAreaId'].setValue(
      parseInt(this.payrollAreaForm.controls['lmsAreaId'].value)
    );
    this.payrollAreaForm.controls['isFixedDenominator'].setValue(
      parseInt(this.payrollAreaForm.controls['isFixedDenominator'].value)
    );
    this.payrollAreaForm.controls['expenseReimbursementAreaMasterId'].setValue(
      parseInt(
        this.payrollAreaForm.controls['expenseReimbursementAreaMasterId'].value
      )
    );

    // if (this.is30daysPayroll == true) {
    //   this.payrollAreaForm.controls['isFixedDenominator'].setValue('Yes');
    // } else {
    //   this.payrollAreaForm.controls['isFixedDenominator'].setValue('No');
    // }
    console.log(JSON.stringify(this.payrollAreaForm.value));
    this.isLoader=true;
    this.service.savePayrollArea(this.payrollAreaForm.value).subscribe(
      (res) => {
        this.isLoader=false;
        this.toaster.sweetalertMasterSuccess(
          'Payroll Area Master Saved Successfully',
          ''
        );
        this.resetForm();
        this.getPayrollAreaDetails();
      },
      (error) => {
        this.isLoader=false;
        this.toaster.sweetalertError(error['error']['status']['message']);
      }
    );
  }
  editPayroll(data) {
    console.log(data);
    this.editFlag = true;
    this.viewFlag = false;
    this.payrollAreaForm.enable();
    this.payrollAreaForm.patchValue(data);

    if (data.isActive == 0) {
      this.isShowRemark = true;
      this.payrollAreaForm.get('remark').setValidators(Validators.required);
      this.payrollAreaForm.get('remark').updateValueAndValidity();
    } else {
      this.payrollAreaForm.get('remark').clearValidators();
      this.payrollAreaForm.get('remark').updateValueAndValidity();
      this.isShowRemark = false;
    }
    this.setFrequency(data.businessCycleDefinitionId);
    this.sdmName = this.payrollAreaForm.controls['minNetPaySDM'].value;
    this.getAllDerivedSDM(this.payrollAreaForm.controls['minNetPaySDM'].value);
    this.payrollAreaMasterId = data.payrollAreaId;
    this.payrollAreaForm.controls['headGroupDefinitionId'].setValue(
      data.headGroupDefinitionResponse.headGroupDefinitionId
    );
    this.payrollAreaForm.controls['arraylistCompanyIds'].setValue(
      data.companyId
    );
    this.payrollAreaForm.controls['payrollAreaCode'].disable();
    this.payrollAreaForm.controls['effectiveFromDate'].setValue(
      this.datepipe.transform(new Date(data.effectiveFromDate), 'yyyy-MM-dd') +
        ' 00:00:00'
    );
    this.payrollAreaForm.controls['effectiveToDate'].setValue(
      this.datepipe.transform(new Date(data.effectiveToDate), 'yyyy-MM-dd') +
        ' 00:00:00'
    );
    
    this.selectedFromDate = new Date(data.effectiveFromDate);
    this.selectedToDate = new Date(data.effectiveToDate);

    if (data.payslipShowFrom != '' && data.payslipShowFrom != null) {
      this.payrollAreaForm.controls['payslipShowFrom'].setValue(
        this.datepipe.transform(new Date(data.payslipShowFrom), 'yyyy-MM-dd') +
          ' 00:00:00'
      );
      this.selectedPayslipDate = new Date(data.payslipShowFrom);
    }

    if (data.taxsheetShowFrom != '' && data.taxsheetShowFrom != null) {
      this.payrollAreaForm.controls['taxsheetShowFrom'].setValue(
        this.datepipe.transform(new Date(data.taxsheetShowFrom), 'yyyy-MM-dd') +
          ' 00:00:00'
      );
      this.selectedTaxsheetDate = new Date(data.taxsheetShowFrom);
    }

    this.payrollAreaForm.addControl('companyId', new FormControl(''));
    //  this.payrollAreaForm.controls['IncomeTaxSettingTemplateName'].setValue(data.IncomeTaxSettingTemplateName)

    window.scrollTo(0, 0);
  }
  viewPayroll(data) {
    this.editFlag = false;
    this.viewFlag = true;
    this.payrollAreaForm.disable();
    this.payrollAreaForm.patchValue(data);
    if (data.isActive == 0) {
      this.isShowRemark = true;
    } else {
      this.isShowRemark = false;
    }
    this.setFrequency(data.businessCycleDefinitionId);
    this.getAllDerivedSDM(this.payrollAreaForm.controls['minNetPaySDM'].value);

    this.payrollAreaMasterId = data.payrollAreaId;
    this.payrollAreaForm.controls['headGroupDefinitionId'].setValue(
      data.headGroupDefinitionResponse.headGroupDefinitionId
    );
    this.payrollAreaForm.controls['arraylistCompanyIds'].setValue(
      data.companyId
    );
    this.payrollAreaForm.controls['effectiveFromDate'].setValue(
      this.datepipe.transform(new Date(data.effectiveFromDate), 'yyyy-MM-dd') +
        ' 00:00:00'
    );
    this.payrollAreaForm.controls['effectiveToDate'].setValue(
      this.datepipe.transform(new Date(data.effectiveToDate), 'yyyy-MM-dd') +
        ' 00:00:00'
    );
  
    this.selectedFromDate = new Date(data.effectiveFromDate);
    this.selectedToDate = new Date(data.effectiveToDate);

    if (data.payslipShowFrom != '' && data.payslipShowFrom != null) {
      this.payrollAreaForm.controls['payslipShowFrom'].setValue(
        this.datepipe.transform(new Date(data.payslipShowFrom), 'yyyy-MM-dd') +
          ' 00:00:00'
      );
      this.selectedPayslipDate = new Date(data.payslipShowFrom);
    }

    if (data.taxsheetShowFrom != '' && data.taxsheetShowFrom != null) {
      this.payrollAreaForm.controls['taxsheetShowFrom'].setValue(
        this.datepipe.transform(new Date(data.taxsheetShowFrom), 'yyyy-MM-dd') +
          ' 00:00:00'
      );
      this.selectedTaxsheetDate = new Date(data.taxsheetShowFrom);
    }
    this.payrollAreaForm.addControl('companyId', new FormControl(''));
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }
  update() {
    this.payrollAreaForm.controls['companyId'].setValue(
      this.payrollAreaForm.controls['arraylistCompanyIds'].value
    );
    this.payrollAreaForm.controls['defaultAttendace'].setValue(
      parseInt(this.payrollAreaForm.controls['defaultAttendace'].value)
    );
    this.payrollAreaForm.controls['businessCycleDefinitionId'].setValue(
      parseInt(this.payrollAreaForm.controls['businessCycleDefinitionId'].value)
    );
    this.payrollAreaForm.controls['headGroupDefinitionId'].setValue(
      parseInt(this.payrollAreaForm.controls['headGroupDefinitionId'].value)
    );
    this.payrollAreaForm.controls['lmsAreaId'].setValue(
      parseInt(this.payrollAreaForm.controls['lmsAreaId'].value)
    );
    this.payrollAreaForm.controls['isFixedDenominator'].setValue(
      parseInt(this.payrollAreaForm.controls['isFixedDenominator'].value)
    );
    this.payrollAreaForm.controls['expenseReimbursementAreaMasterId'].setValue(
      parseInt(
        this.payrollAreaForm.controls['expenseReimbursementAreaMasterId'].value
      )
    );

   
    let Data = {
      businessCycleDefinitionId: parseInt(
        this.payrollAreaForm.controls['businessCycleDefinitionId'].value
      ),
      companyId: this.payrollAreaForm.controls['arraylistCompanyIds'].value,
      createdBy: 'rahul',
      currency: this.payrollAreaForm.controls['currency'].value,
      defaultAttendace: parseInt(
        this.payrollAreaForm.controls['defaultAttendace'].value
      ),
      defaultLanguage: this.payrollAreaForm.controls['defaultLanguage'].value,
      effectiveFromDate:
        this.payrollAreaForm.controls['effectiveFromDate'].value,
      effectiveToDate: this.payrollAreaForm.controls['effectiveToDate'].value,
      headGroupDefinitionId: parseInt(
        this.payrollAreaForm.controls['headGroupDefinitionId'].value
      ),
      // is30daysPayroll: this.is30daysPayroll==true?1:0,
      isActive: parseInt(this.payrollAreaForm.controls['isActive'].value),
      lmsAreaId: parseInt(this.payrollAreaForm.controls['lmsAreaId'].value),
      minNetPaySDM: this.payrollAreaForm.controls['minNetPaySDM'].value,
      minNetPayDerivedId: parseInt(
        this.payrollAreaForm.controls['minNetPayDerivedId'].value
      ),
      payrollAreaCode: this.payrollAreaForm.controls['payrollAreaCode'].value,
      payrollAreaDescription:
        this.payrollAreaForm.controls['payrollAreaDescription'].value,
      remark: this.payrollAreaForm.controls['remark'].value,
      uom: this.payrollAreaForm.controls['uom'].value,
      areaMasterId: 2,
      expenseReimbursementAreaMasterId:
        this.payrollAreaForm.controls['expenseReimbursementAreaMasterId'].value,
      jvGroupName: this.payrollAreaForm.controls['jvGroupName'].value,
      //    "IncomeTaxSettingTemplateName": this.payrollAreaForm.controls['IncomeTaxSettingTemplateName'].value,
      incomeTaxSettingTemplateName:
        this.payrollAreaForm.controls['incomeTaxSettingTemplateName'].value,
      paymentTrackingGroupName:
        this.payrollAreaForm.controls['paymentTrackingGroupName'].value,
      standardminperDays:
        this.payrollAreaForm.controls['standardminperDays'].value,
      payslipShowFrom: this.payrollAreaForm.controls['payslipShowFrom'].value,
      taxsheetShowFrom: this.payrollAreaForm.controls['taxsheetShowFrom'].value,
      isFixedDenominator: this.payrollAreaForm.controls['isFixedDenominator'].value,
      isFixedDenominatorDays: this.payrollAreaForm.controls['isFixedDenominatorDays'].value,
    };
    this.isLoader=true;
    this.service.updatePayrollArea(Data, this.payrollAreaMasterId).subscribe(
      (res) => {
        this.isLoader=false;
        this.toaster.sweetalertMasterSuccess(
          'Payroll Area Master Updated Successfully',
          ''
        );
        this.resetForm();
        this.getPayrollAreaDetails();
      },
      (error) => {
        this.isLoader=false;
        this.toaster.sweetalertError(error['error']['status']['message']);
      }
    );
  }
  exportAsXLSX(data, sheetName): void {
    this.excelData = [];
    this.header = [];
    this.header = [
      'Code',
      'Description',
      'Cycle Definition',
      'PHG',
      'Default Attendance',
      'Fixed Days Denominator (Days)',
      'UOM',
      'Company Name',
      'From Date',
      'To Date',
      'Active',
    ];
    data.forEach((element) => {
      let obj = {
        Code: element.payrollAreaCode,
        Description: element.payrollAreaDescription,
        'Cycle Definition': element.businessCycleDefinitionName,
        PHG: element.headGroupDefinitionResponse.headGroupDefinitionName,
        'Default Attendance': element.isDefaultAttendace === 'Yes' ? '1' : '0',
        'Fixed Days Denominator (Days)':element.isFixedDenominatorDays!=null && element.isFixedDenominatorDays!=='No' ? Number(element.isFixedDenominatorDays): 0,
        UOM: element.uom,
        'Company Name': element.fullName,
        'From Date': this.datepipe.transform(
          new Date(element.effectiveFromDate),
          'dd-MMM-yyyy'
        ),
        'To Date': this.datepipe.transform(
          new Date(element.effectiveToDate),
          'dd-MMM-yyyy'
        ),
        Active: element.isActive == 1 ? 'Yes' : 'No',
      };
      this.excelData.push(obj);
    });
    this.excelservice.exportAsExcelFile(
      this.excelData,
      sheetName,
      sheetName,
      this.header
    );
  }
  openPopup(document: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      document,
      Object.assign({}, { class: 'gray modal-md' })
    );
    this.commonPopup.openPopup();
  }

  setFrequency(event) {
    let index = this.businessCycleData.findIndex((e) => e.id == event);

    if (index != -1) {
      this.frequency = this.businessCycleData[index].frequencyMaster.name;
    } else {
      this.frequency = '';
    }
  }

  smallpopup(template: TemplateRef<any>, payrollAreaCode: any) {
    this.summaryHistory = [];
    this.service.getPayrollAreaHistory(payrollAreaCode).subscribe(
      (res) => {
        this.summaryHistory = res.data.results;
        this.summaryHistory.forEach((element) => {
          element.effectiveFromDate = new Date(element.effectiveFromDate);
          element.effectiveToDate = new Date(element.effectiveToDate);
          if (element.defaultAttendace == 1) {
            element.isDefaultAttendace = 'Yes';
          } else {
            element.isDefaultAttendace = 'No';
          }
          if (element.isActive == 1) {
            element.isActiveT = 'Yes';
          } else {
            element.isActiveT = 'No';
          }
          this.companyData.forEach((com) => {
            if (com.groupCompanyId == element.companyId) {
              element.companyName = com.shortName;
            }
          });
          this.businessCycleData.forEach((cycle) => {
            if (
              parseInt(cycle.id) == parseInt(element.businessCycleDefinitionId)
            ) {
              element.businessCycleDefinitionName = cycle.name;
            }
          });
        });
        this.modalRef = this.modalService.show(
          template,
          Object.assign({}, { class: 'gray modal-xl' })
        );
      },
      (error) => {
        this.toaster.sweetalertError(error['error']['status']['messsage']);
      }
    );
  }


  returnList() {
    const currentValue = this.payrollAreaForm.controls['arraylistCompanyIds'].value;
  
    // Check if currentValue is an array
    if (Array.isArray(currentValue)) {
      return currentValue; // Return as is if it's already a list
    } else {
      return [currentValue]; // Convert to list and return
    }
  }

  onSelectCompany(event) {
    this.payrollAreaForm.get('paymentTrackingGroupName').setValue('');
    if (event != '') {
      this.paymentTrackingGroupData = this.paymentTrackingGroupDataTemp.filter((e) => e.groupCompanyId === event);
    } else {
      this.paymentTrackingGroupData = this.paymentTrackingGroupDataTemp;
    }
  }
}
