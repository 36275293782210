import { Component, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltersService } from '../filters.service';
import { JobInformationService } from '../../employee-master/components/job-information/job-information.service';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { ExcelserviceService } from 'src/app/core/services/excelservice.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  public modalRef: BsModalRef;
  display: boolean = true;
  dateFilterForm: FormGroup;
  employeeFilterForm: FormGroup;
  jobDetailsFilterForm: FormGroup;
  allFilterSummary: any;
  editFilterFlag: boolean = false;
  viewFilterFlag: boolean = false;
  filterName: any = '';
  deleteFilterMasterId: any = ''
  employeeSetData: any;
  employeeServiceAgeData: any;
  employeeAgeData: any;
  employeeLanguageData: any;
  employeeSkillData: any;
  employeeEducationData: any;
  jobInformationData: any;
  companyData: any;
  establishmentList: any;
  restTypeList: any;
  filteredGradeList: any = [];
  filteredDepartmentList: any = [];
  filteredBusinessAreaList: any = [];
  filteredCostCentreList: any = [];
  filteredWorkLocationList: any = [];
  filterdStrategicBusinessUnitList: any = [];
  filteredDivisionList: any = [];
  filteredDesignation1: any = [];
  filteredDesignation2: any = [];
  statusAndEmpTypeList: any = [];
  filterEmpTypeList: any = [];
  filterEmpStatusList: any = [];
  filterEmpTaxList: any = [];
  filterProfitCenterList: any = [];
  companyMasterId: any = 1;
  businessAreaMasterId: any;
  costCentreId: any;
  departmentMasterId: any;
  designation1MasterId: any;
  designation2MasterId: any;
  divisionMasterId: any;
  empStatusId: any;
  employeeTaxCategoryId: any;
  employeeTypeId: any;
  establishmentMasterId: any;
  gradeMasterId: any;
  payrollAreaCode: any;
  strategicBusinessUnitId: any;
  subAreaId: any;
  subDepartmentId: any;
  subLocationMasterId: any;
  workLocationMasterId: any;
  businessAreaMasterIdData: any = [];
  costCentreIdData: any = [];
  departmentMasterIdData: any = [];
  designation1MasterIdData: any = [];
  designation2MasterIdData: any = [];
  divisionMasterIdData: any = [];
  empStatusIdData: any = [];
  employeeTaxCategoryIdData: any = [];
  employeeTypeIdData: any = [];
  establishmentMasterIdData: any = [];
  gradeMasterIdData: any = [];
  payrollAreaCodeData: any = [];
  strategicBusinessUnitIdData: any = [];
  subAreaIdData: any = [];
  subDepartmentIdData: any = [];
  subLocationMasterIdData: any = [];
  workLocationMasterIdData: any = [];
  filterMasterId = 0;
  isCurrentCycle: boolean = false;
  isCurrentWeek: boolean = false;
  isCurrentMonth: boolean = false;
  isCurrentQuarter: boolean = false;
  isCurrentBusinessYear: boolean = false;
  isCurrentCalendarYear: boolean = false;
  is30Days: boolean = false;
  is7Days: boolean = false;
  isToday: boolean = false;
  employeeset: any;
  serviceFromAge: string = '';
  serviceToAge: string = '';
  empFromAge: string = '';
  empToAge: string = '';
  businessCycleDefinitionId: any;
  @Output() filterEvent = new EventEmitter<string>();
  @Output() AllFiltersEvent = new EventEmitter<any>();
  filterApplyData: any;
  getAllPayrollareaData: any;
  getAllJobMasters: any;
  getAllPayrollareacodeBycompanyids: any;
  jobMasterType: any;
  getEstablishmentDetails: any;
  getAllFileterDataByFilterMasterId: any;
  getAllPayrollareacodeBycompanyidsData: any;
  getEstablishmentDetailsData: any;
  jobMasterTypes: any = [];
  fixedData: any[];
  allData: any[];
  jobMasterDropDown: any[];
  subCostCenterIdData: any = [];
  profitCentreMasterIdData: any = [];
  position1MasterIdData: any = [];
  position2MasterIdData: any = [];
  position6MasterIdData: any = [];
  job5MasterIdData: any = [];
  position7MasterIdData: any = [];
  position3MasterIdData: any = [];
  job1MasterIdData: any = [];
  job2MasterIdData: any = [];
  job3MasterIdData: any = [];
  job4MasterIdData: any = [];
  noOfCalculationFlag: boolean = false;
  cycleSelectionOption: any = '';
  cycleDefifitionList: any[];
  cycleDefinition: any;
  businessCycleDefinationId: any;
  selctedCycleName: any;
  cycleNameList: any[];
  periodDefList: any;
  showCycleDefinitionFlag: boolean = false;
  showCylces: boolean = false;
  showFromAndToCycles: boolean = false;
  selectedCyclerange: string;
  groupCompanyId: any = 0;
  groupComapnyIds: any[] = [];
  displayedColumns: string[] = [];
  employeeList: any[] = [];
  companySessionData: any;
  globalCompanyMasterIdList: any = [];
  isApplyAsSaveBtn: boolean = false;
  position4MasterIdData: any[] = [];
  position5MasterIdData: any[] = [];
  isFilterByEmployee: boolean =  false;
  isFilterByJobDetails: boolean =  false;
  isFilterByDate: boolean =  false;
  excelData: any[];
  header: any[];


  constructor(private filtersService: FiltersService, private JobInformationService: JobInformationService, 
    public alertService: AlertServiceService,private alerservice: AlertServiceService, private modalService: BsModalService, 
    private authService: AuthService, private datepipe: DatePipe,private excelservice: ExcelserviceService,) {
    this.createFilterByEmployeeForm();
    this.createFilterByJobDetailsForm();
    this.createFilterByDateForm();
    this.companyMasterId = this.authService.deValue(sessionStorage.getItem('globalCompanyList'))

    this.companySessionData = authService.getComapnyFromSession()
    this.globalCompanyMasterIdList = this.companySessionData.globalCompanyMasterId

    /** For single report page set page name to apply filter and set flag for delete when download report 04/04/24 */
    this.isApplyAsSaveBtn = false;
    if (this.filtersService.getFilterPageName() == 'Single-Report') {
      this.isApplyAsSaveBtn = true;
    }

  }

  ngOnInit(): void {
    this.getAllFiltersSummary();
    /** Employee Dropdown Api */
    this.getEmployeeSet();
    this.getEmployeeServiceAge();
    this.getEmployeeAge();
    this.getEmployeeLanguage();
    this.getEmployeeSkill();
    this.getEmployeeEducation();
    /** Job Info Dropdown API */
    this.getAllGroupCompany();
    this.getAllCycleDefinition()
  }

  /******************************** 3 Forms initialization ******************************/
  /** Filter By Employee Form */
  createFilterByEmployeeForm() {
    this.employeeFilterForm = new FormGroup({
      "education": new FormControl(''),
      "employeeAge": new FormControl(''),
      "employeeList": new FormControl(''),
      "employeeSet": new FormControl(''),
      "filterByEmployeeId": new FormControl(0),
      "gender": new FormControl(''),
      "languageId": new FormControl(''),
      "serviceAge": new FormControl(''),
      "skillId": new FormControl(''),
      "status": new FormControl('')
    })
    this.filterName = ''
    this.serviceFromAge = ''
    this.serviceToAge = ''
    this.empFromAge = ''
    this.empToAge = ''
    this.companyMasterId = null
    this.payrollAreaCode = ''
    this.establishmentMasterId = ''
    this.subLocationMasterId = ''
    this.workLocationMasterId = ''
    this.businessAreaMasterId = ''
    this.subAreaId = ''
    this.strategicBusinessUnitId = ''
    this.divisionMasterId = ''
    this.departmentMasterId = ''
    this.subDepartmentId = ''
    this.costCentreId = ''
    this.employeeTypeId = ''
    this.empStatusId = ''
    this.gradeMasterId = ''
    this.designation1MasterId = ''
    this.designation2MasterId = ''
  }
  /** Filter By Job Details Form */
  createFilterByJobDetailsForm() {
    this.jobDetailsFilterForm = new FormGroup({
      "businessAreaMasterId": new FormControl(''),
      "costCentreId": new FormControl(''),
      "departmentMasterId": new FormControl(''),
      "designation1MasterId": new FormControl(''),
      "designation2MasterId": new FormControl(''),
      "divisionMasterId": new FormControl(''),
      "empStatusId": new FormControl(''),
      "employeeTaxCategoryId": new FormControl(''),
      "employeeTypeId": new FormControl(''),
      "establishmentMasterId": new FormControl(''),
      "filterByJobDetailsId": new FormControl(0),
      "gradeMasterId": new FormControl(''),
      "job1MasterId": new FormControl(''),
      "job2MasterId": new FormControl(''),
      "job3MasterId": new FormControl(''),
      "job4MasterId": new FormControl(''),
      "job5MasterId": new FormControl(''),
      "payrollAreaId": new FormControl(''),
      "position1MasterId": new FormControl(''),
      "position2MasterId": new FormControl(''),
      "position3MasterId": new FormControl(''),
      "position4MasterId": new FormControl(''),
      "position5MasterId": new FormControl(''),
      "profitCentreMasterId": new FormControl(''),
      "strategicBusinessUnitId": new FormControl(''),
      "subAreaId": new FormControl(''),
      "subCostCenterId": new FormControl(''),
      "subDepartmentId": new FormControl(''),
      "subLocationMasterId": new FormControl(''),
      "workLocationMasterId": new FormControl(''),
    })

    this.businessAreaMasterId = ""
    this.costCentreId = ""
    this.departmentMasterId = ""
    this.designation1MasterId = ""
    this.designation2MasterId = ""
    this.divisionMasterId = ""
    this.empStatusId = ""
    this.employeeTaxCategoryId = ""
    this.employeeTypeId = ""
    this.establishmentMasterId = ""
    this.gradeMasterId = ""
    this.payrollAreaCode = ""
    this.strategicBusinessUnitId = ""
    this.subAreaId = ""
    this.subDepartmentId = ""
    this.subLocationMasterId = ""
    this.workLocationMasterId = ""

    this.jobMasterDropDown = []
  }
  /** Filter By Date Form */
  createFilterByDateForm() {
    this.dateFilterForm = new FormGroup(
      {
        // "active": new FormControl(true),
        // "createdBy": new FormControl("rahul"),
        // "createDateTime": new FormControl(new Date()),
        "endDate": new FormControl(''),
        "filterByDateId": new FormControl(0),
        // "filterMasterId": new FormControl(0),
        "fromCycle": new FormControl("0"),
        "isCyclewise": new FormControl(false),
        "isSummation": new FormControl(false),
        "fromDate": new FormControl(''),
        "isCurrentBusinessYear": new FormControl(false),
        "isCurrentCalendarYear": new FormControl(false),
        "isCurrentCycle": new FormControl(false),
        "isCurrentMonth": new FormControl(false),
        "isCurrentQuarter": new FormControl(false),
        "isCurrentWeek": new FormControl(false),
        "isLast30Days": new FormControl(false),
        "isLast7Days": new FormControl(false),
        "isToday": new FormControl(false),
        "noOfPreviousBusinessYear": new FormControl(0),
        "noOfPreviousCycle": new FormControl(0),
        "noOfPreviousMonths": new FormControl(0),
        "noOfPreviousQuarters": new FormControl(0),
        "noOfPreviousWeeks": new FormControl(0),
        // "lastModifiedBy": new FormControl(""),
        // "lastModifiedDateTime": new FormControl(""),
        "periodDefinition": new FormControl("0"),
        "toCycle": new FormControl("0"),
        "cycleDefinitionId": new FormControl(""),
        // "businessCycleDefinitionId": new FormControl(""),
        // "frequencyMasterId": new FormControl(""),
        // "periodName": new FormControl("")
      }
    )
  }


  /****************************************** Employee Form *********************************/
  /** Get Employee Set Data */
  getEmployeeSet() {
    this.filtersService.getEmployeeSet().subscribe(res => {
      this.employeeSetData = res.data.results[0];
      this.employeeSetData.sort((a, b) => a.employeeSetName.localeCompare(b.employeeSetName))
    })
  }
  /** Get Employee Service Age Data */
  getEmployeeServiceAge() {
    this.employeeServiceAgeData = []
    let allValues;
    this.filtersService.getEmployeeServiceAge().subscribe(res => {
      this.employeeServiceAgeData = [
        "30 Year 11 Month 7 Days", "9 Year 5 Month 26 Days", "9 Year 5 Month 25 Days", "9 Year 5 Month 24 Days"
      ]
    })
  }
  /** Get Employee Age Data */
  getEmployeeAge() {
    this.employeeAgeData = []
    let allValues;
    this.filtersService.getEmployeeAge().subscribe(res => {
      this.employeeAgeData = [
        "30 Year 11 Month 7 Days", "9 Year 5 Month 26 Days", "29 Year 7 Month 1 Days", "10 Year 6 Month 6 Days", "33 Year 6 Month 0 Days", "33 Year 1 Month 24 Days", "47 Year 11 Month 11 Days", "48 Year 5 Month 8 Days", "42 Year 0 Month 27 Days", "10 Year 4 Month 26 Days", "17 Year 5 Month 27 Days", "64 Year 5 Month 12 Days", "34 Year 6 Month 1 Days", "10 Year 4 Month 27 Days", "40 Year 6 Month 7 Days", "40 Year 5 Month 6 Days", "35 Year 8 Month 20 Days", "40 Year 11 Month 0 Days", "41 Year 0 Month 6 Days", "49 Year 5 Month 8 Days"
      ]
    })
  }
  /** Get Employee Language Data */
  getEmployeeLanguage() {
    this.employeeLanguageData = []
    this.filtersService.getEmployeeLanguage().subscribe(res => {
      // this.employeeLanguageData = res.data.results[0];
      res.data.results[0].sort((a, b) => a.language.localeCompare(b.language))

      let data = [];
      res.data.results[0].forEach(element => {
        data.push(element.language)
      });

      if (data.length > 0) {
        data = [...new Set(data)]
        data.forEach(ele => {
          this.employeeLanguageData.push({
            'name': ele
          })
        })

      }
    })
  }
  /** Get Employee Skill Data */
  getEmployeeSkill() {
    this.filtersService.getEmployeeSkill().subscribe(res => {
      // this.employeeSkillData = res.data.results[0];
      res.data.results[0].sort((a, b) => a.skillName.localeCompare(b.skillName))

      let data = [];
      res.data.results[0].forEach(element => {
        data.push(element.skillName)
      });

      if (data.length > 0) {
        data = [...new Set(data)]
        this.employeeSkillData = []

        data.forEach(ele => {
          this.employeeSkillData.push({
            'name': ele
          })
        })
      }
    })
  }
  /** Get Employee Education Data */
  getEmployeeEducation() {
    this.filtersService.getEmployeeEducation().subscribe(res => {
      // this.employeeEducationData = res.data.results[0];
      res.data.results[0].sort((a, b) => a.education.localeCompare(b.education))
      let data = [];
      res.data.results[0].forEach(element => {
        data.push(element.education)
      });

      if (data.length > 0) {
        data = [...new Set(data)]
        this.employeeEducationData = []

        data.forEach(ele => {
          this.employeeEducationData.push({
            'name': ele
          })
        })
      }
    })
  }


  /** Get Search selected Value */
  getSelectedValue(event, value) {
    if (value == 'EmpSet' && event.value != null) {
      this.employeeSetData.forEach(element => {
        if (element.employeeSetName == event.value.employeeSetName) {
          this.employeeset = element.establishmentMasterId
        }
      });
    }
 }


  /****************************************** Job Info Form *********************************/
  /** Get Job Master Information  and payroll list, establishment list*/
  getJobMasterInformation(id) {
    this.getAllJobMasters = []
    this.getAllPayrollareacodeBycompanyids = []
    let groupCompanyIdList = []
    this.getEstablishmentDetails = []
    let jobMasterType = []

    this.workLocationMasterIdData = []
    this.departmentMasterIdData = []
    this.costCentreIdData = []
    this.gradeMasterIdData = []
    this.designation1MasterIdData = []
    this.subLocationMasterIdData = []
    this.businessAreaMasterIdData = []
    this.subAreaIdData = []
    this.divisionMasterIdData = []
    this.subDepartmentIdData = []
    this.subCostCenterIdData = []
    this.profitCentreMasterIdData = []
    this.designation2MasterIdData = []
    this.position1MasterIdData = []
    this.position2MasterIdData = []
    this.job1MasterIdData = []
    this.job2MasterIdData = []
    this.job3MasterIdData = []
    this.job4MasterIdData = []
    this.position3MasterIdData = []
    this.position7MasterIdData = []
    this.position6MasterIdData = []
    this.employeeTypeIdData = []
    this.strategicBusinessUnitIdData = []
    this.job5MasterIdData = []
   this.payrollAreaCodeData = []

    id.value?.forEach(element => {
      if (element.code != '' && element.code != null) {
        groupCompanyIdList.push(element.code)
      }
    });
    this.groupComapnyIds = groupCompanyIdList
    let data = {
      groupCompanyIdList: groupCompanyIdList
    }
    this.fixedData = []
    let tempdata = []
    let resArr = [];

    this.groupComapnyIds.forEach(element => {
      this.filtersService.getAllJobMasater(element).subscribe(res => {

      })
    });

   this.filtersService.getAllJobMasaterData(data).subscribe(res => {
      this.getAllJobMasters = res.data.results;
      this.getAllJobMasters.forEach(element => {
        this.jobMasterTypes.push(element.jobMasterType)
      });
      if (this.jobMasterTypes) {
        this.jobMasterTypes = [...new Set(this.jobMasterTypes)]
        for (let i = 0; i < this.jobMasterTypes.length; i++) {
          const result = this.getAllJobMasters.filter((o) => o.jobMasterType == this.jobMasterTypes[i])
          const set = new Set(result)
          set.forEach((o: any) => {
            if (this.fixedData.length == 0) {
              tempdata.push
                ({
                  active: o.active,
                  companyName: o.companyName,
                  fromDate: o.fromDate,
                  groupCompanyId: o.groupCompanyId,
                  jobMasterMappingId: o.jobMasterMappingId,
                  jobMasterType: o.jobMasterType,
                  jobMasterValueId: o.jobMasterValueId,
                  masterCode: o.masterCode,
                  masterDescription: o.masterDescription,
                  toDate: o.toDate
                })
            } else {
              tempdata.push
                ({
                  active: o.active,
                  companyName: o.companyName,
                  fromDate: o.fromDate,
                  groupCompanyId: o.groupCompanyId,
                  jobMasterMappingId: o.jobMasterMappingId,
                  jobMasterType: o.jobMasterType,
                  jobMasterValueId: o.jobMasterValueId,
                  masterCode: o.masterCode,
                  masterDescription: o.masterDescription,
                  toDate: o.toDate
                })
            }
            this.fixedData[i] =
              ({
                active: o.active,
                companyName: o.companyName,
                fromDate: o.fromDate,
                groupCompanyId: o.groupCompanyId,
                jobMasterMappingId: o.jobMasterMappingId,
                jobMasterType: o.jobMasterType,
                jobMasterValueId: o.jobMasterValueId,
                toDate: o.toDate,
              });
          });
        }
        tempdata.forEach(function (item) {
          var i = resArr.findIndex(x => x.jobMasterType == item.jobMasterType);
          if (i <= -1) {
            resArr.push(item);
          }
        });
        resArr?.forEach(ele => {
          ele.dropdownData = []
        })
        this.allData = tempdata
      }
      if (resArr.length > 0) {
        this.allData?.forEach((element, index) => {
          resArr?.forEach(ele => {
            if (element.jobMasterType == ele.jobMasterType) {
              let ind = index;
              ele.dropdownData.push({
                name: element.masterCode,
                code: element.jobMasterValueId,
              })
            }
          })
        });
        this.jobMasterDropDown = resArr
      }
    })
    //Get Payrollarea Code
    // let data1 = {
    //   groupCompanyIdList: groupCompanyIdList,
    //   "jobMasterType": "BusinessArea"
    // }
    // this.filtersService.getAllPayrollareacodeBycompanyids(data1).subscribe(res => {

    this.groupComapnyIds.forEach(element => {
      this.filtersService.getAllPayrollareacodeBycompanyids(element).subscribe(res => {
        res.data.results[0].forEach(element => {
          const obj = {
            name: element.payrollAreaCode,
            code: element.id,
          };
          this.getAllPayrollareacodeBycompanyids.push(obj);
        })
      })
      this.filtersService.getEstablishmentDetails(element).subscribe(res => {
        res.data.results[0].forEach(element => {
          const obj = {
            name: element.establishmentCode,
            code: element.establishmentMasterId,
          };
          this.getEstablishmentDetails.push(obj);
        })
      })
    })
    // this.filtersService.getAllFileterDataByFilterMasterId(data1).subscribe(res => {
    //   this.getAllFileterDataByFilterMasterId = res.data.results[0];
    // })
  }

  /**Get Group Company Name List */
  getAllGroupCompany() {
    this.companyData = []
    this.filtersService.getAllGroupCompany().subscribe(res => {
      console.log(res.data.results)
      let obj;
      res.data.results.forEach(element => {
        obj = {
          'code': element.groupCompanyId,
          'name': element.companyName
        };
        this.companyData.push(obj)
      });
    })
  }

  /** Get Other Job Information */
  getOtherJobInformation(id) {
    this.establishmentList = [];
    this.JobInformationService.getEstaDetails().subscribe(res => {
      res.data.results.forEach(element => {
        const obj = {
          name: element.establishmentCode,
          code: element.establishmentMasterId,
        };
        this.establishmentList.push(obj);
      });
    });
    this.JobInformationService.getOtherMasterDetails(id).subscribe(res => {
      this.restTypeList = res.data.results;
      var data = [];
      for (let i = 0; i < this.restTypeList.length; i++) {
        data.push({
          jobMasterMappingId: this.restTypeList[i].jobMasterMappingId,
          jobMasterType: this.restTypeList[i].jobMasterType,
          masterCode: this.restTypeList[i].masterCode,
          masterDescription: this.restTypeList[i].masterDescription
        });
      }
      const type = data.filter((item) => {
        if (item.jobMasterType == 'Grade') {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredGradeList.push(obj);
        }
        if (item.jobMasterType == "Department") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredDepartmentList.push(obj);
        }
        if (item.jobMasterType == "BusinessArea") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredBusinessAreaList.push(obj);
        }
        if (item.jobMasterType == "CostCentre") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredCostCentreList.push(obj);
        }
        if (item.jobMasterType == "WorkLocation") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredWorkLocationList.push(obj);
        }
        if (item.jobMasterType == "StrategicBusinessUnit") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filterdStrategicBusinessUnitList.push(obj);
        }
        if (item.jobMasterType == "Division") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredDivisionList.push(obj);
        }
        if (item.jobMasterType == "Designation1") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredDesignation1.push(obj);
        }
       if (item.jobMasterType == "Designation2") {
          const obj = {
            name: item.masterCode,
            code: item.jobMasterMappingId,
          }
          this.filteredDesignation2.push(obj);
        }
      })
    });
    this.JobInformationService.getPositionDD().subscribe(res => {
      this.statusAndEmpTypeList = res.data.results;
      var data = [];
      for (let i = 0; i < this.statusAndEmpTypeList.length; i++) {
        data.push({
          positionDetailDDId: this.statusAndEmpTypeList[i].positionDetailDDId,
          jobMasterType: this.statusAndEmpTypeList[i].category,
          masterCode: this.statusAndEmpTypeList[i].value,
          description: this.statusAndEmpTypeList[i].description
        });
      }
      const type = data.filter((item) => {
        if (item.jobMasterType == 'Employee Type') {
          const obj = {
            name: item.masterCode,
            code: item.positionDetailDDId,
          }
          this.filterEmpTypeList.push(obj);
        }
        if (item.jobMasterType == 'Employee Status') {
          const obj = {
            name: item.masterCode,
            code: item.positionDetailDDId,
          }
          this.filterEmpStatusList.push(obj);
        }
        if (item.jobMasterType == 'Employee Tax Category') {
          const obj = {
            name: item.masterCode,
            code: item.positionDetailDDId,
          }
          this.filterEmpTaxList.push(obj);
        }
      });
    });
  }

  /** Get Job Field Change Data */
  onSelectPayrollArea(evt) {
    this.payrollAreaCodeData = []
    console.log(evt)

    //  evt.value.forEach(ele=>{
    //   this.payrollAreaCodeData.push(ele.code)
    //  })
    this.filtersService.getPayrollAreaDetails().subscribe(res => {
      res.data.results.forEach(element => {
        evt.value.forEach(ele => {
          if (element.payrollAreaCode == ele.name) {
            this.payrollAreaCodeData.push(element.payrollAreaId)
          }
        })
      });
     console.log(this.payrollAreaCodeData)
    })
  }

  onSelectEstablishment(evt) {

    console.log(evt)
    this.establishmentMasterIdData = []
    evt.value.forEach(ele => {
      this.establishmentMasterIdData.push(ele.code)
    });
    console.log(this.establishmentMasterIdData)
  }

  onSelectJobMasterData(evt, data) {

    // this.workLocationMasterIdData = []
    // this.departmentMasterIdData = []
    // this.costCentreIdData = []
    // this.gradeMasterIdData = []
    // this.designation1MasterIdData = []
    // this.subLocationMasterIdData = []
    // this.businessAreaMasterIdData = []
    // this.subAreaIdData = []
    // this.divisionMasterIdData = []
    // this.subDepartmentIdData = []
    // this.subCostCenterIdData = []
    // this.profitCentreMasterIdData = []
    // this.designation2MasterIdData = []
    // this.position1MasterIdData = []
    // this.position2MasterIdData = []
    // this.job1MasterIdData = []
    // this.job2MasterIdData = []
    // this.job3MasterIdData = []
    // this.job4MasterIdData = []
    // this.position3MasterIdData = []
    // this.position7MasterIdData = []
    // this.position6MasterIdData = []
    // this.employeeTypeIdData = []
    // this.strategicBusinessUnitIdData = []
    // this.job5MasterIdData = []

    console.log(evt)
    console.log(data.jobMasterType)

    if (data.jobMasterType == 'WorkLocation') {
      this.workLocationMasterIdData = []
      evt.value.forEach(ele => {
        this.workLocationMasterIdData.push(ele.code)
      });
      console.log(this.workLocationMasterIdData)
    }
    if (data.jobMasterType == 'Department') {
    this.departmentMasterIdData = []
      evt.value.forEach(ele => {
        this.departmentMasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'CostCentre') {
      this.costCentreIdData = []
      evt.value.forEach(ele => {
        this.costCentreIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Grade') {
      this.gradeMasterIdData = []
      evt.value.forEach(ele => {
        this.gradeMasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Designation1') {
      this.designation1MasterId = []
      evt.value.forEach(ele => {
        this.designation1MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'SubLocation') {
      this.subLocationMasterIdData = []
      evt.value.forEach(ele => {
        this.subLocationMasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'BusinessArea') {
      this.businessAreaMasterIdData = []
      evt.value.forEach(ele => {
        this.businessAreaMasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'SubArea') {
      this.subAreaIdData = []
      evt.value.forEach(ele => {
       this.subAreaIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Division') {
      this.divisionMasterIdData = []
      evt.value.forEach(ele => {
        this.divisionMasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'SubDepartment') {
      this.subDepartmentIdData = []
      evt.value.forEach(ele => {
        this.subDepartmentIdData.push(ele.code)
      });
      console.log(this.subDepartmentIdData)
    }
    if (data.jobMasterType == 'subDepartment') {
      this.subDepartmentIdData = []
      evt.value.forEach(ele => {
        this.subDepartmentIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'SubCostCenter') {
      this.subCostCenterIdData = []
      evt.value.forEach(ele => {
        this.subCostCenterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'ProfitCentre') {
      this.profitCentreMasterIdData = []
      evt.value.forEach(ele => {
        this.profitCentreMasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Designation2') {
      this.designation2MasterIdData = []
      evt.value.forEach(ele => {
        this.designation2MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position1') {
      this.position1MasterIdData = []
      evt.value.forEach(ele => {
        this.position1MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position2') {
      this.position2MasterIdData = []
      evt.value.forEach(ele => {
        this.position2MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Job1') {
      this.job1MasterIdData = []
      evt.value.forEach(ele => {
        this.job1MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Job2') {
      this.job2MasterIdData = []
      evt.value.forEach(ele => {
        this.job2MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Job3') {
      this.job3MasterIdData =[]
      evt.value.forEach(ele => {
        this.job3MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Job4') {
      this.job4MasterIdData = []
      evt.value.forEach(ele => {
        this.job4MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position3') {
      this.position3MasterIdData = []
      evt.value.forEach(ele => {
        this.position3MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position7') {
      this.position7MasterIdData = []
      evt.value.forEach(ele => {
        this.position7MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position6') {
      this.position6MasterIdData = []
      evt.value.forEach(ele => {
        this.position6MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position4') {
      this.position4MasterIdData = []
      evt.value.forEach(ele => {
        this.position4MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Position5') {
      this.position5MasterIdData = []
      evt.value.forEach(ele => {
        this.position5MasterIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'EmployeeType1') {
      this.employeeTypeIdData = []
      evt.value.forEach(ele => {
        this.employeeTypeIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'StrategicBusinessUnit') {
      this.strategicBusinessUnitIdData = []
      evt.value.forEach(ele => {
        this.strategicBusinessUnitIdData.push(ele.code)
      });
    }
    if (data.jobMasterType == 'Job5') {
      this.job5MasterIdData = []
      evt.value.forEach(ele => {
        this.job5MasterIdData.push(ele.code)
      });
    }
  }

  onSelectSubLocation(evt) {
    this.subLocationMasterIdData = []
    this.subLocationMasterIdData.push(evt.itemValue.code)
  }

  onSelectWorkLocation(evt) {
    this.workLocationMasterIdData.push(evt.itemValue.code)
  }

  onSelectBusinessArea(evt) {
    this.businessAreaMasterIdData.push(evt.itemValue.code)
  }

  onSelectSubArea(evt) {
    this.subAreaIdData.push(evt.itemValue.code)
  }

  onSelectSBU(evt) {
    this.strategicBusinessUnitIdData.push(evt.itemValue.code)
  }

  onSelectDivision(evt) {
    this.divisionMasterIdData.push(evt.itemValue.code)
  }

  onSelectDepartment(evt) {
    this.departmentMasterIdData.push(evt.itemValue.code)
  }

  onSelectSubDepartment(evt) {
    this.subDepartmentIdData.push(evt.itemValue.code)
  }

  onSelectCostCenter(evt) {
    this.costCentreIdData.push(evt.itemValue.code)
  }

  onSelectEmpType(evt) {
    this.employeeTypeIdData.push(evt.itemValue.code)
  }

  onSelectEmpStatus(evt) {
    this.empStatusIdData.push(evt.itemValue.code)
  }

  onSelectGrade(evt) {
    this.gradeMasterIdData.push(evt.itemValue.code)
  }

  onSelectDesignation1(evt) {
    this.designation1MasterIdData.push(evt.itemValue.code)
  }

  onSelectDesignation2(evt) {
    this.designation2MasterIdData.push(evt.itemValue.code)
  }

  /******************************* By Date Filter ***************************************/
  /** Radio Button Selection */
  getByDateFilter(name) {
    if (name = "currentCycle") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(true)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "currentWeek") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(true)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "currentMonth") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(true)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "currentQuarter") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(true)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "currentBusinessYear") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(true)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "currentCalendarYear") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(true)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "last30Days") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(true)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "last7Days") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(true)
      this.dateFilterForm.controls['isToday'].setValue(false)
    }
    if (name = "today") {
      this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
      this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
      this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
      this.dateFilterForm.controls['isLast30Days'].setValue(false)
      this.dateFilterForm.controls['isLast7Days'].setValue(false)
      this.dateFilterForm.controls['isToday'].setValue(true)
    }
  }
  /** Cyclewise and summation dropdown selection */
  getDateFilterSelection(value) {
    if (value == 'Cyclewise') {
      this.dateFilterForm.controls['isCyclewise'].setValue(true)
      this.dateFilterForm.controls['isSummation'].setValue(false)
    } else {
      this.dateFilterForm.controls['isCyclewise'].setValue(false)
      this.dateFilterForm.controls['isSummation'].setValue(true)
    }
  }


  /******************************** Summary Table ***************************************/
  /** Get all filters summary Data */
  getAllFiltersSummary() {
    this.allFilterSummary = []
    this.filtersService.getAllFiltersSummary().subscribe(res => {
      res.data.results[0].forEach(element => {
        // if (element.isActive) {
        this.allFilterSummary.push(element)
        // }
      });
    })
  }

  copyFrom(value) {
    this.filterMasterId = value
  }

  filterApply() {
    this.filtersService.getEmployeeJobinformationData(this.filterMasterId).subscribe(res => {
      this.filterApplyData = res.data.results[0];
      this.filterEvent.emit(this.filterApplyData);
      this.display = false
    })
  }

  /********************************* Button Click ***********************************/
  resetForms() {
    this.noOfCalculationFlag = false;
    this.showCycleDefinitionFlag = false;
    this.showFromAndToCycles = false;
    this.showCylces = false;
    this.selectedCyclerange = '';
    this.cycleSelectionOption = '';

    this.dateFilterForm.reset();
    this.jobDetailsFilterForm.reset();
    this.employeeFilterForm.reset();
    this.createFilterByDateForm();
    this.createFilterByEmployeeForm();
    this.createFilterByJobDetailsForm();
    this.editFilterFlag = false;
    this.viewFilterFlag = false;
    this.payrollAreaCodeData = []
    this.isFilterByEmployee = false
    this.isFilterByJobDetails = false
    this.isFilterByDate = false
  }

  /**Save All Filter Data */
  saveAllFilters(value) {

    this.employeeFilterForm.controls['filterByEmployeeId'].setValue('')
    this.jobDetailsFilterForm.controls['filterByJobDetailsId'].setValue('')

    console.log("employee form: ",Object.values(this.employeeFilterForm.value).every(value => value === ''));
    console.log("job form: ",Object.values(this.jobDetailsFilterForm.value).every(value => value === ''));
    // console.log("date form: ",this.dateFilterForm.pristine && Object.values(this.dateFilterForm.value).every(value => value === ''));

    this.isFilterByEmployee = false 
    this.isFilterByJobDetails = false
    

    if((Object.values(this.employeeFilterForm.value).every(value => value === '') ) == false){
       this.isFilterByEmployee = true
    }

    if(this.employeeList.length > 0){
      this.isFilterByEmployee = true 
    }

    

  //  if(this.employeeList.length > 0){
  //   this.isFilterByJobDetails = true 
  // }

    let flagValue = ''
    if (value == 'Apply') {
      if (!this.isApplyAsSaveBtn) {
        flagValue = 'No'
      }
    }

    let languageId = []
    if (this.employeeFilterForm.controls['languageId'].value != '') {
      let langid = this.employeeFilterForm.controls['languageId'].value
      langid?.forEach(element => {
        languageId.push(element.name)
      });
    }

    let skillId = []
    if (this.employeeFilterForm.controls['skillId'].value != '') {
      let skills = this.employeeFilterForm.controls['skillId'].value
      skills?.forEach(element => {
        skillId.push(element.name)
      });
    }

    let education = []
    if (this.employeeFilterForm.controls['education'].value != '') {
      let edu = this.employeeFilterForm.controls['education'].value
      edu?.forEach(element => {
        education.push(element.name)
      });
    }

    this.employeeFilterForm.controls['filterByEmployeeId'].setValue(0)
    this.employeeFilterForm.controls['serviceAge'].setValue(this.serviceFromAge + '-' + this.serviceToAge)
    this.employeeFilterForm.controls['employeeAge'].setValue(this.empFromAge + '-' + this.empToAge)
    this.employeeFilterForm.controls['employeeList'].setValue(this.employeeList.toString())
    this.employeeFilterForm.controls['languageId'].setValue(languageId.toString())
    this.employeeFilterForm.controls['skillId'].setValue(skillId.toString())
    this.employeeFilterForm.controls['education'].setValue(education.toString())

    this.dateFilterForm.controls['noOfPreviousBusinessYear'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousBusinessYear'].value))
    this.dateFilterForm.controls['noOfPreviousCycle'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousCycle'].value))
    this.dateFilterForm.controls['noOfPreviousMonths'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousMonths'].value))
    this.dateFilterForm.controls['noOfPreviousQuarters'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousQuarters'].value))
    this.dateFilterForm.controls['noOfPreviousWeeks'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousWeeks'].value))

    this.jobDetailsFilterForm.controls['filterByJobDetailsId'].setValue(0)
    this.jobDetailsFilterForm.controls['payrollAreaId'].setValue(this.payrollAreaCodeData.toString())
    this.jobDetailsFilterForm.controls['establishmentMasterId'].setValue(this.establishmentMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subLocationMasterId'].setValue(this.subLocationMasterIdData.toString())
    this.jobDetailsFilterForm.controls['workLocationMasterId'].setValue(this.workLocationMasterIdData.toString())
    this.jobDetailsFilterForm.controls['businessAreaMasterId'].setValue(this.businessAreaMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subAreaId'].setValue(this.subAreaIdData.toString())
    this.jobDetailsFilterForm.controls['strategicBusinessUnitId'].setValue(this.strategicBusinessUnitIdData.toString())
    this.jobDetailsFilterForm.controls['divisionMasterId'].setValue(this.divisionMasterIdData.toString())
    this.jobDetailsFilterForm.controls['departmentMasterId'].setValue(this.departmentMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subDepartmentId'].setValue(this.subDepartmentIdData.toString())
    this.jobDetailsFilterForm.controls['costCentreId'].setValue(this.costCentreIdData.toString())
    this.jobDetailsFilterForm.controls['employeeTypeId'].setValue(this.employeeTypeIdData.toString())
    this.jobDetailsFilterForm.controls['empStatusId'].setValue(this.empStatusIdData.toString())
    this.jobDetailsFilterForm.controls['gradeMasterId'].setValue(this.gradeMasterIdData.toString())
    this.jobDetailsFilterForm.controls['designation1MasterId'].setValue(this.designation1MasterIdData.toString())
    this.jobDetailsFilterForm.controls['designation2MasterId'].setValue(this.designation2MasterIdData.toString())
    this.jobDetailsFilterForm.controls['profitCentreMasterId'].setValue(this.profitCentreMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subCostCenterId'].setValue(this.subCostCenterIdData.toString())
    this.jobDetailsFilterForm.controls['position1MasterId'].setValue(this.position1MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position2MasterId'].setValue(this.position2MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position3MasterId'].setValue(this.position3MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position4MasterId'].setValue(this.position4MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position5MasterId'].setValue(this.position5MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job1MasterId'].setValue(this.job1MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job2MasterId'].setValue(this.job2MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job3MasterId'].setValue(this.job3MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job4MasterId'].setValue(this.job4MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job5MasterId'].setValue(this.job5MasterIdData.toString())

    if((Object.values(this.jobDetailsFilterForm.value).every(value => value === '') ) == false){
      this.isFilterByJobDetails = true
   }

    this.dateFilterForm.enable()
    this.dateFilterForm.controls['cycleDefinitionId'].setValue(parseInt(this.dateFilterForm.controls['cycleDefinitionId'].value))
    this.dateFilterForm.controls['periodDefinition'].setValue(parseInt(this.dateFilterForm.controls['periodDefinition'].value))

    if(this.dateFilterForm.controls['fromDate'].value != ''){
      this.dateFilterForm.controls['fromDate'].setValue(this.datepipe.transform(new Date(this.dateFilterForm.controls['fromDate'].value),'yyyy-MM-dd'))
      this.dateFilterForm.controls['endDate'].setValue(this.datepipe.transform(new Date(this.dateFilterForm.controls['endDate'].value),'yyyy-MM-dd'))
    }

    let data = [{
      "filterByEmployeeRequestDTOList": [this.employeeFilterForm.value],
      "filterByJobDetailsRequestDTOList": [this.jobDetailsFilterForm.value],
      "filterByDateRequestDTOList":[this.dateFilterForm.value],
      "filterMasterId": 0,
      "flag": flagValue,
      "globalCompanyMasterIdList": this.groupComapnyIds.toString(),
      "groupCompanyId": this.globalCompanyMasterIdList,
      "name": this.filterName,
      "pageNo": 0,
      "recordCount": 0,
      "isFilterByEmployee": this.isFilterByEmployee,
      "isFilterByJobDetails": this.isFilterByJobDetails,
      "isFilterByDate": this.isFilterByDate
    }]


    console.log(data)

    console.log(this.employeeFilterForm)

    console.log(JSON.stringify(data))
    if (value == 'Apply') {
      this.filtersService.saveAllFilters(data).subscribe(res => {
        this.filterApplyData = res.data.results[0][0].employeeMasterLandingPageList;
        console.log(this.filterApplyData)
        this.filtersService.setFilterMasterId(res.data.results[0][0].filterMasterId)
        this.filterEvent.emit(this.filterApplyData);
        this.AllFiltersEvent.emit();
        this.display = false
      })
    } else {
      this.filtersService.saveAllFilters(data).subscribe(res => {
        this.alerservice.sweetalertMasterSuccess('Filter Master Saved Successfully', '');
        this.getAllFiltersSummary();
        this.resetForms();
        this.AllFiltersEvent.emit();
      })
    }

  }

  /** Edit All Filter Data */
  editFilter(data) {
    console.log(data)
    this.isCurrentCycle = false;
    this.isCurrentWeek = false;
    this.isCurrentMonth = false;
    this.isCurrentQuarter = false;
    this.isCurrentBusinessYear = false;
    this.isCurrentCalendarYear = false;
    this.is30Days = false;
    this.is7Days = false;
    this.isToday = false;

    this.getAllPayrollareacodeBycompanyids = []
    this.getEstablishmentDetails = []

    this.establishmentMasterId = []
    this.payrollAreaCode = []
    this.payrollAreaCodeData = []

    this.editFilterFlag = true;
    this.viewFilterFlag = false;
    if(data.filterByEmployeeResponseDTOList){
      this.employeeFilterForm.patchValue(data.filterByEmployeeResponseDTOList[0])
    }
    
    // this.dateFilterForm.patchValue(data.filterByDateList[0])
    if(data.filterByJobDetailsResponseDTOList){
      console.log("In If")
      this.jobDetailsFilterForm.patchValue(data.filterByJobDetailsResponseDTOList[0])
      console.log(this.jobDetailsFilterForm)
    }
    console.log(data.filterByJobDetailsResponseDTOList[0])
    this.filterName = data.name;
    this.filterMasterId = data.filterMasterId;

    /** By Date Filter */
    if(data.filterByDateResponseDTOList){
      this.isCurrentCycle = data.filterByDateResponseDTOList[0]?.isCurrentCycle;
      this.isCurrentWeek = data.filterByDateResponseDTOList[0]?.isCurrentWeek;
      this.isCurrentMonth = data.filterByDateResponseDTOList[0]?.isCurrentMonth;
      this.isCurrentQuarter = data.filterByDateResponseDTOList[0]?.isCurrentQuarter;
      this.isCurrentBusinessYear = data.filterByDateResponseDTOList[0]?.isCurrentBusinessYear;
      this.isCurrentCalendarYear = data.filterByDateResponseDTOList[0]?.isCurrentCalendarYear;
      this.is30Days = data.filterByDateResponseDTOList[0]?.isLast30Days;
      this.is7Days = data.filterByDateResponseDTOList[0]?.isLast7Days;
      this.isToday = data.filterByDateResponseDTOList[0]?.isToday;
  
      if(data.filterByDateResponseDTOList[0]?.fromDate != '' && data.filterByDateResponseDTOList[0]?.endDate != ''){
        this.cycleSelectionOption = 'Custom Defined'
      }
    }
    

    if(this.isCurrentCycle == true){
      this.cycleSelectionOption = 'Current Cycle'
    }
    if(this.isCurrentWeek == true){
      this.cycleSelectionOption = 'Current Week'
    }
    if(this.isCurrentMonth == true){
      this.cycleSelectionOption = 'Current Month'
    }
    if(this.isCurrentQuarter == true){
      this.cycleSelectionOption = 'Current Quarter'
    }
    if(this.isCurrentBusinessYear == true){
      this.cycleSelectionOption = 'Current Business Year'
    }
    if(this.isCurrentCalendarYear == true){
      this.cycleSelectionOption = 'Current Calender Year'
    }
    if(this.is30Days == true){
      this.cycleSelectionOption = 'Last 30 Days'
    }
    if(this.is7Days == true){
      this.cycleSelectionOption = 'Last 7 Days'
    }
    if(this.isToday == true){
      this.cycleSelectionOption = 'Today'
    }
    if(data.filterByDateResponseDTOList){
      if(data.filterByDateResponseDTOList[0]?.fromCycle != ''){
        this.cycleSelectionOption = 'Cycle Range'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousCycle != 0){
        this.cycleSelectionOption = 'No. of Previous Cycle'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousWeeks != 0){
        this.cycleSelectionOption = 'No. of Previous Weeks'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousMonths != 0){
        this.cycleSelectionOption = 'No. of Previous Months'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousQuarters != 0){
        this.cycleSelectionOption = 'No. of Previous Quarters'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousBusinessYear != 0){
        this.cycleSelectionOption = 'No. of Previous Business Years'
      }
    } 
   
    
    this.getcycleSelect(this.cycleSelectionOption)
    if(data.filterByDateResponseDTOList){
      this.getCycleNameById(data.filterByDateResponseDTOList[0]?.cycleDefinitionId)
    }
    setTimeout(() => {
      if(data.filterByDateResponseDTOList){
        data.filterByDateResponseDTOList[0].fromDate = new Date(data.filterByDateResponseDTOList[0]?.fromDate)
        data.filterByDateResponseDTOList[0].endDate = new Date(data.filterByDateResponseDTOList[0]?.endDate)
        this.dateFilterForm.patchValue(data.filterByDateResponseDTOList[0])
      }    
    }, 2000);
   
    /** By Employee Filter */
    let empAge = this.employeeFilterForm.controls['employeeAge'].value
    let serviceAge = this.employeeFilterForm.controls['serviceAge'].value
    empAge = empAge.split('-');
    // console.log(empAge)
    this.empFromAge = empAge[0]
    this.empToAge = empAge[1]
    serviceAge = serviceAge.split('-')
    this.serviceFromAge = serviceAge[0]
    this.serviceToAge = serviceAge[1];

    if(data.filterByEmployeeResponseDTOList){
        let langid = data.filterByEmployeeResponseDTOList[0].languageId.split(",")
        let languageId = []
        let lan;
        if (langid.length == 1) {
          langid.forEach(element => {
            lan = [element]
          })
          this.employeeFilterForm.controls['languageId'].setValue(lan)
        } else {
          let langObj = []
          langid.forEach(element => {
            langObj.push({
              'name': element
            })
            languageId = langObj
            this.employeeFilterForm.controls['languageId'].setValue(languageId)
          });
        }

        let skills = data.filterByEmployeeResponseDTOList[0].skillId.split(",")
        let skillId = []
        let skill;
        if (skills.length == 1) {
          skills.forEach(element => {
            skill = [element]
          })
          this.employeeFilterForm.controls['skillId'].setValue(skill)
        } else {
          let skillObj = []
          skills.forEach(element => {
            skillObj.push({
              'name': element
            })
            skillId = skillObj
            this.employeeFilterForm.controls['skillId'].setValue(skillId)
          });
        }


        let edu = data.filterByEmployeeResponseDTOList[0].education.split(",")
        let education = []
        let ed;
        if (edu.length == 1) {
          edu.forEach(element => {
            ed = [element]
          })
          this.employeeFilterForm.controls['education'].setValue(ed)
        } else {
          let edObj = []
          edu.forEach(element => {
            edObj.push({
              'name': element
            })
            education = edObj
            this.employeeFilterForm.controls['education'].setValue(education)
          });
        }
      }

    console.log(data)
    if (data.groupCompanyId == 0) {
      data.groupCompanyId = this.globalCompanyMasterIdList
    }

    console.log(this.companyMasterId)
    this.companyData.forEach(element => {
      //console.log(parseInt(element.code))
      if (data.groupCompanyId == element.code) {
        this.companyMasterId = [element];
        console.log(this.companyMasterId)

        this.groupComapnyIds = this.companyMasterId[0].code
        let data = {
          groupCompanyIdList: [this.companyMasterId[0].code]
        }
        this.fixedData = []
        let tempdata = []
        let resArr = [];
        this.filtersService.getAllJobMasaterData(data).subscribe(res => {
          this.getAllJobMasters = res.data.results;
         this.getAllJobMasters.forEach(element => {
            this.jobMasterTypes.push(element.jobMasterType)
          });
          if (this.jobMasterTypes) {
            this.jobMasterTypes = [...new Set(this.jobMasterTypes)]
            for (let i = 0; i < this.jobMasterTypes.length; i++) {
              const result = this.getAllJobMasters.filter((o) => o.jobMasterType == this.jobMasterTypes[i])
              const set = new Set(result)
              set.forEach((o: any) => {
                if (this.fixedData.length == 0) {
                  tempdata.push
                    ({
                      active: o.active,
                      companyName: o.companyName,
                      fromDate: o.fromDate,
                      groupCompanyId: o.groupCompanyId,
                      jobMasterMappingId: o.jobMasterMappingId,
                      jobMasterType: o.jobMasterType,
                      jobMasterValueId: o.jobMasterValueId,
                      masterCode: o.masterCode,
                      masterDescription: o.masterDescription,
                      toDate: o.toDate
                    })
                } else {
                  tempdata.push
                    ({
                      active: o.active,
                      companyName: o.companyName,
                      fromDate: o.fromDate,
                      groupCompanyId: o.groupCompanyId,
                      jobMasterMappingId: o.jobMasterMappingId,
                      jobMasterType: o.jobMasterType,
                      jobMasterValueId: o.jobMasterValueId,
                      masterCode: o.masterCode,
                      masterDescription: o.masterDescription,
                      toDate: o.toDate
                    })
                }
                this.fixedData[i] =
                  ({
                    active: o.active,
                    companyName: o.companyName,
                    fromDate: o.fromDate,
                    groupCompanyId: o.groupCompanyId,
                    jobMasterMappingId: o.jobMasterMappingId,
                    jobMasterType: o.jobMasterType,
                    jobMasterValueId: o.jobMasterValueId,
                    toDate: o.toDate,
                  });
              });
            }
            tempdata.forEach(function (item) {
              var i = resArr.findIndex(x => x.jobMasterType == item.jobMasterType);
              if (i <= -1) {
                resArr.push(item);
              }
            });
            resArr?.forEach(ele => {
              ele.dropdownData = []
            })
            this.allData = tempdata
          }
          if (resArr.length > 0) {
            this.allData?.forEach((element, index) => {
              resArr?.forEach(ele => {
                if (element.jobMasterType == ele.jobMasterType) {
                  let ind = index;
                  ele.dropdownData.push({
                    name: element.masterCode,
                    code: element.jobMasterValueId,
                  })
                }
              })
            });
            this.jobMasterDropDown = resArr
          }
        })

        //Get Payrollarea Code
        let data1 = {
          groupCompanyIdList: [this.companyMasterId[0].code],
          "jobMasterType": "BusinessArea"
        }
        this.filtersService.getAllPayrollareacodeBycompanyids(this.companyMasterId[0].code).subscribe(res => {
          res.data.results[0].forEach(element => {
            const obj = {
              name: element.payrollAreaCode,
              code: element.payrollAreaId,
            };
            this.getAllPayrollareacodeBycompanyids.push(obj);
          })
        })
        this.filtersService.getEstablishmentDetails(this.companyMasterId[0].code).subscribe(res => {
          res.data.results[0].forEach(element => {
            const obj = {
              name: element.establishmentCode,
              code: element.establishmentMasterId,
           };
            this.getEstablishmentDetails.push(obj);
          })
        })
      }
    });


    this.workLocationMasterIdData = []
    this.departmentMasterIdData = []
    this.costCentreIdData = []
    this.gradeMasterIdData = []
    this.designation1MasterIdData = []
    this.subLocationMasterIdData = []
    this.businessAreaMasterIdData = []
    this.subAreaIdData = []
    this.divisionMasterIdData = []
    this.subDepartmentIdData = []
    this.subCostCenterIdData = []
    this.profitCentreMasterIdData = []
    this.designation2MasterIdData = []
    this.position1MasterIdData = []
    this.position2MasterIdData = []
    this.job1MasterIdData = []
    this.job2MasterIdData = []
    this.job3MasterIdData = []
    this.job4MasterIdData = []
    this.position3MasterIdData = []
    this.position7MasterIdData = []
    this.position6MasterIdData = []
    this.employeeTypeIdData = []
    this.strategicBusinessUnitIdData = []
    this.job5MasterIdData = []


    setTimeout(() => {

      /*********************************** Set Establishment *******************/
        if(data.filterByJobDetailsResponseDTOList){
          let establishment = data.filterByJobDetailsResponseDTOList[0].establishmentMasterId?.split(",")
          if (establishment.length == 1) {
            this.getEstablishmentDetails?.forEach(element => {
              if (element.code == parseInt(establishment[0])) {
                this.establishmentMasterId = [element]
              }
            });
          } else {
            let obj = []
            this.getEstablishmentDetails?.forEach(element => {
              establishment.forEach(ele => {
                if (element.code == ele) {
                  obj.push(element)
                  this.establishmentMasterId = obj
                }
              });
            });
          }

          /*********************************** Set payroll id *******************/
          let pay = data.filterByJobDetailsResponseDTOList[0].payrollAreaId.split(",");
          if (pay.length == 1) {
            this.filtersService.getPayrollAreaDetails().subscribe(res => {
              res.data.results.forEach(element => {
                if (parseInt(element.payrollAreaId) == parseInt(pay[0])) {
                  this.payrollAreaCodeData.push(element.payrollAreaId)
                  let obj = {
                    name: element.payrollAreaCode,
                    code: element.payrollAreaId
                  }
                  this.payrollAreaCode = [obj]
                }
              });
            })
            
          } else {
            this.filtersService.getPayrollAreaDetails().subscribe(res => {
              let obj = []
              res.data.results.forEach(element => {
                pay.forEach(payroll => {
                  if (parseInt(element.payrollAreaId) == parseInt(payroll)) {
                    this.payrollAreaCodeData.push(element.payrollAreaId)
                    // this.payrollAreaCode.push({
                    //   name: element.payrollAreaCode,
                    //   code: element.payrollAreaId
                    // })

                    obj.push({
                      name: element.payrollAreaCode,
                      code: element.payrollAreaId
                    })
                    this.payrollAreaCode = obj
                  }
                })
              });
            })
          }
        } 

      if (this.jobMasterDropDown.length > 0) {
        /***************** set job master data ******************/
        console.log(this.jobMasterDropDown)
        console.log(data.filterByJobDetailsResponseDTOList)
        if(data.filterByJobDetailsResponseDTOList){
          let obj = []
          this.jobMasterDropDown.forEach(ele => {
            if (ele.jobMasterType == 'WorkLocation') {
              let workLocationMasterId = data.filterByJobDetailsResponseDTOList[0].workLocationMasterId.split(',')
              if (workLocationMasterId.length == 1) {
                
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(workLocationMasterId)) {
                    this.workLocationMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                workLocationMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      obj.push(e)
                      this.workLocationMasterIdData.push(e.code)
                      ele.selectedValue = obj
                    }
                  })
                });
                
              }
            }
            if (ele.jobMasterType == 'Department') {
              let objdept = []
              let departmentMasterId = data.filterByJobDetailsResponseDTOList[0].departmentMasterId.split(',')
              if (departmentMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(departmentMasterId)) {
                    this.departmentMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                departmentMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objdept.push(e)
                      this.departmentMasterIdData.push(e.code)
                      ele.selectedValue = objdept
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'CostCentre') {
              let objcost = []
              let costCentreId = data.filterByJobDetailsResponseDTOList[0].costCentreId.split(',')
              if (costCentreId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(costCentreId)) {
                    this.costCentreIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                costCentreId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objcost.push(e)
                      this.costCentreIdData.push(e.code)
                      ele.selectedValue = objcost
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Grade') {
              let objgrade = []
              let gradeMasterId = data.filterByJobDetailsResponseDTOList[0].gradeMasterId.split(',')
              if (gradeMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(gradeMasterId)) {
                    this.gradeMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                gradeMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objgrade.push(e)
                      this.gradeMasterIdData.push(e.code)
                      ele.selectedValue = objgrade
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Designation1') {
              let objdesg1 = []
              let designation1MasterId = data.filterByJobDetailsResponseDTOList[0].designation1MasterId.split(',')
              if (designation1MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(designation1MasterId)) {
                    this.designation1MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                designation1MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objdesg1.push(e)
                      this.designation1MasterIdData.push(e.code)
                      ele.selectedValue = objdesg1
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubLocation') {
              let objsublocation = []
              let subLocationMasterId = data.filterByJobDetailsResponseDTOList[0].subLocationMasterId.split(',')
              if (subLocationMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subLocationMasterId)) {
                    this.subLocationMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subLocationMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objsublocation.push(e)
                      this.subLocationMasterIdData.push(e.code)
                      ele.selectedValue = objsublocation
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'BusinessArea') {
              let objBA = []
              let businessAreaMasterId = data.filterByJobDetailsResponseDTOList[0].businessAreaMasterId.split(',')
              if (businessAreaMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(businessAreaMasterId)) {
                    this.businessAreaMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                businessAreaMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objBA.push(e)
                      this.businessAreaMasterIdData.push(e.code)
                      ele.selectedValue = objBA
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubArea') {
              let objSubArea = []
              let subAreaId = data.filterByJobDetailsResponseDTOList[0].subAreaId.split(',')
              if (subAreaId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subAreaId)) {
                    this.subAreaIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subAreaId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objSubArea.push(e)
                      this.subAreaIdData.push(e.code)
                      ele.selectedValue = objSubArea
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Division') {
              let objDivision = []
              let divisionMasterId = data.filterByJobDetailsResponseDTOList[0].divisionMasterId.split(',')
              if (divisionMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(divisionMasterId)) {
                    this.divisionMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                divisionMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objDivision.push(e)
                      this.divisionMasterIdData.push(e.code)
                      ele.selectedValue = objDivision
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubDepartment') {
              let objSubDept = []
              let subDepartmentId = data.filterByJobDetailsResponseDTOList[0].subDepartmentId.split(',')
              if (subDepartmentId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subDepartmentId)) {
                    this.subDepartmentIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subDepartmentId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objSubDept.push(e)
                      this.subDepartmentIdData.push(e.code)
                      ele.selectedValue = objSubDept
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubCostCenter') {
              let objSubCost = []
              let subCostCenterId = data.filterByJobDetailsResponseDTOList[0].subCostCenterId.split(',')
              if (subCostCenterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subCostCenterId)) {
                    this.subCostCenterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subCostCenterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objSubCost.push(e)
                      this.subCostCenterIdData.push(e.code)
                      ele.selectedValue = objSubCost
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'ProfitCentre') {
              let objProfit = []
              let profitCentreMasterId = data.filterByJobDetailsResponseDTOList[0].profitCentreMasterId.split(',')
              if (profitCentreMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(profitCentreMasterId)) {
                    this.profitCentreMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                profitCentreMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objProfit.push(e)
                      this.profitCentreMasterIdData.push(e.code)
                      ele.selectedValue = objProfit
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Designation2') {
              let objDesg2 = []
              let designation2MasterId = data.filterByJobDetailsResponseDTOList[0].designation2MasterId.split(',')
              if (designation2MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(designation2MasterId)) {
                    this.designation2MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                designation2MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objDesg2.push(e)
                      this.designation2MasterIdData.push(e.code)
                      ele.selectedValue = objDesg2
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position1') {
              let objPos1 = []
              let position1MasterId = data.filterByJobDetailsResponseDTOList[0].position1MasterId.split(',')
              if (position1MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position1MasterId)) {
                    this.position1MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position1MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos1.push(e)
                      this.position1MasterIdData.push(e.code)
                      ele.selectedValue = objPos1
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position2') {
              let objPos2 = []
              let position2MasterId = data.filterByJobDetailsResponseDTOList[0].position2MasterId.split(',')
              if (position2MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position2MasterId)) {
                    this.position2MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position2MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos2.push(e)
                      this.position2MasterIdData.push(e.code)
                      ele.selectedValue = objPos2
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Job1') {
              let objJob1 = []
              let job1MasterId = data.filterByJobDetailsResponseDTOList[0].job1MasterId.split(',')
              if (job1MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job1MasterId)) {
                    this.job1MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job1MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob1.push(e)
                      this.job1MasterIdData.push(e.code)
                      ele.selectedValue = objJob1
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Job2') {
              let objJob2 = []
              let job2MasterId = data.filterByJobDetailsResponseDTOList[0].job2MasterId.split(',')
              if (job2MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job2MasterId)) {
                    this.job2MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job2MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob2.push(e)
                      this.job2MasterIdData.push(e.code)
                      ele.selectedValue = objJob2
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Job3') {
              let objJob3 = []
              let job3MasterId = data.filterByJobDetailsResponseDTOList[0].job3MasterId.split(',')
              if (job3MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job3MasterId)) {
                    this.job3MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job3MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob3.push(e)
                      this.job3MasterIdData.push(e.code)
                      ele.selectedValue = objJob3
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Job4') {
              let objJob4 = []
              let job4MasterId = data.filterByJobDetailsResponseDTOList[0].job4MasterId.split(',')
              if (job4MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job4MasterId)) {
                    this.job4MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job4MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob4.push(e)
                      this.job4MasterIdData.push(e.code)
                      ele.selectedValue = objJob4
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Job5') {
              let objJob5 = []
              let job5MasterId = data.filterByJobDetailsResponseDTOList[0].job5MasterId.split(',')
              if (job5MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job5MasterId)) {
                    this.job5MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job5MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob5.push(e)
                      this.job5MasterIdData.push(e.code)
                      ele.selectedValue = objJob5
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position3') {
              let objPos3 = []
              let position3MasterId = data.filterByJobDetailsResponseDTOList[0].position3MasterId.split(',')
              if (position3MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position3MasterId)) {
                    this.position3MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position3MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos3.push(e)
                      this.position3MasterIdData.push(e.code)
                      ele.selectedValue = objPos3
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position4') {
              let objPos4 = []
              let position4MasterId = data.filterByJobDetailsResponseDTOList[0].position4MasterId.split(',')
              if (position4MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position4MasterId)) {
                    this.position4MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position4MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos4.push(e)
                      this.position4MasterIdData.push(e.code)
                      ele.selectedValue = objPos4
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Position5') {
              let objPos5 = []
              let position5MasterId = data.filterByJobDetailsResponseDTOList[0].position5MasterId.split(',')
              if (position5MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position5MasterId)) {
                    this.position5MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position5MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos5.push(e)
                      this.position5MasterIdData.push(e.code)
                      ele.selectedValue = objPos5
                    }
                  })
                })
              }
            }
            if (ele.jobMasterType == 'StrategicBusinessUnit') {
              let objStratBussiness = []
              let strategicBusinessUnitId = data.filterByJobDetailsResponseDTOList[0].strategicBusinessUnitId.split(',')
              if (strategicBusinessUnitId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(strategicBusinessUnitId)) {
                    this.strategicBusinessUnitIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                strategicBusinessUnitId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objStratBussiness.push(e)
                      this.strategicBusinessUnitIdData.push(e.code)
                      ele.selectedValue = objStratBussiness
                    }
                  })
                })
              }
            }
            if (ele.jobMasterType == 'EmployeeType1') {
              let emptypeobj = []
              let employeeTypeId = data.filterByJobDetailsResponseDTOList[0].employeeTypeId.split(',')
              if (employeeTypeId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(employeeTypeId)) {
                    ele.selectedValue = [e]
                    this.employeeTypeIdData.push(e.code)
                  }
                })
              } else {
                employeeTypeId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      emptypeobj.push(e)
                      this.employeeTypeIdData.push(e.code)
                      ele.selectedValue = emptypeobj
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'empStatus') {
              let empstatusobj = []
              let empStatusId = data.filterByJobDetailsResponseDTOList[0].empStatusId.split(',')
              if (empStatusId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(empStatusId)) {
                    this.empStatusIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                empStatusId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      empstatusobj.push(e)
                      this.empStatusIdData.push(e.code)
                      ele.selectedValue = empstatusobj
                    }
                  })
                })
              }
            }
          })
        }  
      }
    }, 2000);


    this.employeeFilterForm.enable()
    this.dateFilterForm.enable()
    this.jobDetailsFilterForm.enable()
  }

  /**Update All Filter Data */
  updateAllFilters() {
    let languageId = []
    if (this.employeeFilterForm.controls['languageId'].value != '') {
      let langid = this.employeeFilterForm.controls['languageId'].value
      langid?.forEach(element => {
        languageId.push(element.name)
      });
    }

    let skillId = []
    if (this.employeeFilterForm.controls['skillId'].value != '') {
      let skills = this.employeeFilterForm.controls['skillId'].value
      skills?.forEach(element => {
        skillId.push(element.name)
      });
    }

    let education = []
    if (this.employeeFilterForm.controls['education'].value != '') {
      let edu = this.employeeFilterForm.controls['education'].value
      edu?.forEach(element => {
        education.push(element.name)
      });
    }

    this.isFilterByEmployee = false 
    this.isFilterByJobDetails = false
    

    if((Object.values(this.employeeFilterForm.value).every(value => value === '') ) == false){
       this.isFilterByEmployee = true
    }

    if((Object.values(this.jobDetailsFilterForm.value).every(value => value === '') ) == false){
      this.isFilterByJobDetails = true
   }

   if(this.employeeFilterForm.controls['filterByEmployeeId'].value == 0){
    this.isFilterByEmployee = false 
   }

   if(this.jobDetailsFilterForm.controls['filterByJobDetailsId'].value == 0){
    this.isFilterByJobDetails = false 
   }

    // let flagValue = ''
    // if (value == 'Apply') {
    //   if (!this.isApplyAsSaveBtn) {
    //     flagValue = 'No'
    //   }
    // }

    if(this.serviceToAge == undefined){
      this.serviceToAge = ''
    }

    if(this.empToAge == undefined){
      this.empToAge = ''
    }

    this.employeeFilterForm.controls['serviceAge'].setValue(this.serviceFromAge + '-' + this.serviceToAge)
    this.employeeFilterForm.controls['employeeAge'].setValue(this.empFromAge + '-' + this.empToAge)
    this.employeeFilterForm.controls['employeeList'].setValue(this.employeeList.toString())
    this.employeeFilterForm.controls['languageId'].setValue(languageId.toString())
    this.employeeFilterForm.controls['skillId'].setValue(skillId.toString())
    this.employeeFilterForm.controls['education'].setValue(education.toString())
    this.dateFilterForm.controls['noOfPreviousBusinessYear'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousBusinessYear'].value))
    this.dateFilterForm.controls['noOfPreviousCycle'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousCycle'].value))
    this.dateFilterForm.controls['noOfPreviousMonths'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousMonths'].value))
    this.dateFilterForm.controls['noOfPreviousQuarters'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousQuarters'].value))
    this.dateFilterForm.controls['noOfPreviousWeeks'].setValue(parseInt(this.dateFilterForm.controls['noOfPreviousWeeks'].value))
    this.jobDetailsFilterForm.controls['payrollAreaId'].setValue(this.payrollAreaCodeData.toString())
    this.jobDetailsFilterForm.controls['establishmentMasterId'].setValue(this.establishmentMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subLocationMasterId'].setValue(this.subLocationMasterIdData.toString())
    this.jobDetailsFilterForm.controls['workLocationMasterId'].setValue(this.workLocationMasterIdData.toString())
    this.jobDetailsFilterForm.controls['businessAreaMasterId'].setValue(this.businessAreaMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subAreaId'].setValue(this.subAreaIdData.toString())
    this.jobDetailsFilterForm.controls['strategicBusinessUnitId'].setValue(this.strategicBusinessUnitIdData.toString())
    this.jobDetailsFilterForm.controls['divisionMasterId'].setValue(this.divisionMasterIdData.toString())
    this.jobDetailsFilterForm.controls['departmentMasterId'].setValue(this.departmentMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subDepartmentId'].setValue(this.subDepartmentIdData.toString())
    this.jobDetailsFilterForm.controls['costCentreId'].setValue(this.costCentreIdData.toString())
    this.jobDetailsFilterForm.controls['employeeTypeId'].setValue(this.employeeTypeIdData.toString())
    this.jobDetailsFilterForm.controls['empStatusId'].setValue(this.empStatusIdData.toString())
    this.jobDetailsFilterForm.controls['gradeMasterId'].setValue(this.gradeMasterIdData.toString())
    this.jobDetailsFilterForm.controls['designation1MasterId'].setValue(this.designation1MasterIdData.toString())
    this.jobDetailsFilterForm.controls['designation2MasterId'].setValue(this.designation2MasterIdData.toString())
    this.jobDetailsFilterForm.controls['profitCentreMasterId'].setValue(this.profitCentreMasterIdData.toString())
    this.jobDetailsFilterForm.controls['subCostCenterId'].setValue(this.subCostCenterIdData.toString())
    this.jobDetailsFilterForm.controls['position1MasterId'].setValue(this.position1MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position2MasterId'].setValue(this.position2MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position3MasterId'].setValue(this.position3MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position4MasterId'].setValue(this.position4MasterIdData.toString())
    this.jobDetailsFilterForm.controls['position5MasterId'].setValue(this.position5MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job1MasterId'].setValue(this.job1MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job2MasterId'].setValue(this.job2MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job3MasterId'].setValue(this.job3MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job4MasterId'].setValue(this.job4MasterIdData.toString())
    this.jobDetailsFilterForm.controls['job5MasterId'].setValue(this.job5MasterIdData.toString())

    this.dateFilterForm.enable()
    this.dateFilterForm.controls['cycleDefinitionId'].setValue(parseInt(this.dateFilterForm.controls['cycleDefinitionId'].value))
    this.dateFilterForm.controls['periodDefinition'].setValue(parseInt(this.dateFilterForm.controls['periodDefinition'].value))

    if(this.dateFilterForm.controls['fromDate'].value != ''){
      this.dateFilterForm.controls['fromDate'].setValue(this.datepipe.transform(new Date(this.dateFilterForm.controls['fromDate'].value),'yyyy-MM-dd'))
      this.dateFilterForm.controls['endDate'].setValue(this.datepipe.transform(new Date(this.dateFilterForm.controls['endDate'].value),'yyyy-MM-dd'))
    }

    let data = [{
      "filterByEmployeeRequestDTOList": [this.employeeFilterForm.value],
      "filterByJobDetailsRequestDTOList": [this.jobDetailsFilterForm.value],
      "filterByDateRequestDTOList":[this.dateFilterForm.value],
      "filterMasterId": this.filterMasterId,
      "flag": "",
      "globalCompanyMasterIdList": this.groupComapnyIds.toString(),
      "groupCompanyId": this.globalCompanyMasterIdList,
      "name": this.filterName,
      "pageNo": 0,
      "recordCount": 0,
      "isFilterByEmployee": this.isFilterByEmployee,
      "isFilterByJobDetails": this.isFilterByJobDetails,
      "isFilterByDate": this.isFilterByDate
    }]
    this.filtersService.updateAllFilters(data).subscribe(res => {
      this.alerservice.sweetalertMasterSuccess('Filter Master Updated Successfully', '');
      this.getAllFiltersSummary();
      this.resetForms();
    })
  }

  /** View All Filter Data */
  // viewFilter(data) {
  //   this.getAllPayrollareacodeBycompanyids = []
  //   this.getEstablishmentDetails = []

  //   this.establishmentMasterId = []
  //   this.payrollAreaCode = []
  //   this.payrollAreaCodeData = []

  //   this.editFilterFlag = false;
  //   this.viewFilterFlag = true;
  //   this.employeeFilterForm.patchValue(data.filterByEmployeeResponseDTOList[0])
  //   // this.dateFilterForm.patchValue(data.filterByDateList[0])
  //   this.jobDetailsFilterForm.patchValue(data.filterByJobDetailsResponseDTOList[0])
  //   this.filterName = data.name;
  //   this.filterMasterId = data.filterMasterId;

  //   /** By Date Filter */
  //   this.isCurrentCycle = data.filterByDateResponseDTOList[0]?.isCurrentCycle;
  //   this.isCurrentWeek = data.filterByDateResponseDTOList[0]?.isCurrentWeek;
  //   this.isCurrentMonth = data.filterByDateResponseDTOList[0]?.isCurrentMonth;
  //   this.isCurrentQuarter = data.filterByDateResponseDTOList[0]?.isCurrentQuarter;
  //   this.isCurrentBusinessYear = data.filterByDateResponseDTOList[0]?.isCurrentBusinessYear;
  //   this.isCurrentCalendarYear = data.filterByDateResponseDTOList[0]?.isCurrentCalendarYear;
  //   this.is30Days = data.filterByDateResponseDTOList[0]?.isLast30Days;
  //   this.is7Days = data.filterByDateResponseDTOList[0]?.isLast7Days;
  //   this.isToday = data.filterByDateResponseDTOList[0]?.isToday;

  //   if(data.filterByDateResponseDTOList[0]?.fromDate != '' && data.filterByDateResponseDTOList[0]?.endDate != ''){
  //     this.cycleSelectionOption = 'Custom Defined'
  //   }

  //   if(this.isCurrentCycle == true){
  //     this.cycleSelectionOption = 'Current Cycle'
  //   }
  //   if(this.isCurrentWeek == true){
  //     this.cycleSelectionOption = 'Current Week'
  //   }
  //   if(this.isCurrentMonth == true){
  //     this.cycleSelectionOption = 'Current Month'
  //   }
  //   if(this.isCurrentQuarter == true){
  //     this.cycleSelectionOption = 'Current Quarter'
  //   }
  //   if(this.isCurrentBusinessYear == true){
  //     this.cycleSelectionOption = 'Current Business Year'
  //  }
  //   if(this.isCurrentCalendarYear == true){
  //     this.cycleSelectionOption = 'Current Calender Year'
  //   }
  //   if(this.is30Days == true){
  //     this.cycleSelectionOption = 'Last 30 Days'
  //   }
  //   if(this.is7Days == true){
  //     this.cycleSelectionOption = 'Last 7 Days'
  //   }
  //   if(this.isToday == true){
  //     this.cycleSelectionOption = 'Today'
  //   }
  //   if(data.filterByDateResponseDTOList[0]?.fromCycle != ''){
  //     this.cycleSelectionOption = 'Cycle Range'
  //   }
  //   if(data.filterByDateResponseDTOList[0]?.noOfPreviousCycle != 0){
  //     this.cycleSelectionOption = 'No. of Previous Cycle'
  //   }
  //   if(data.filterByDateResponseDTOList[0]?.noOfPreviousWeeks != 0){
  //     this.cycleSelectionOption = 'No. of Previous Weeks'
  //   }
  //   if(data.filterByDateResponseDTOList[0]?.noOfPreviousMonths != 0){
  //     this.cycleSelectionOption = 'No. of Previous Months'
  //   }
  //   if(data.filterByDateResponseDTOList[0]?.noOfPreviousQuarters != 0){
  //     this.cycleSelectionOption = 'No. of Previous Quarters'
  //   }
  //   if(data.filterByDateResponseDTOList[0]?.noOfPreviousBusinessYear != 0){
  //     this.cycleSelectionOption = 'No. of Previous Business Years'
  //   }
   
    
  //   this.getcycleSelect(this.cycleSelectionOption)
  //   this.getCycleNameById(data.filterByDateResponseDTOList[0]?.cycleDefinitionId)
  //   setTimeout(() => {
  //     if(data.filterByDateResponseDTOList){
  //       data.filterByDateResponseDTOList[0].fromDate = new Date(data.filterByDateResponseDTOList[0]?.fromDate)
  //       data.filterByDateResponseDTOList[0].endDate = new Date(data.filterByDateResponseDTOList[0]?.endDate)
  //       this.dateFilterForm.patchValue(data.filterByDateResponseDTOList[0])
  //     }    
  //   }, 2000);

  //   /** By Employee Filter */
  //   let empAge = this.employeeFilterForm.controls['employeeAge'].value
  //   let serviceAge = this.employeeFilterForm.controls['serviceAge'].value
  //   empAge = empAge.split('-');
  //   // console.log(empAge)
  //   this.empFromAge = empAge[0]
  //   this.empToAge = empAge[1]
  //   serviceAge = serviceAge.split('-')
  //   this.serviceFromAge = serviceAge[0]
  //   this.serviceToAge = serviceAge[1];

  //   let langid = data.filterByEmployeeResponseDTOList[0].languageId.split(",")
  //   let languageId = []
  //   let lan;
  //   if (langid.length == 1) {
  //     langid.forEach(element => {
  //       lan = [element]
  //     })
  //     this.employeeFilterForm.controls['languageId'].setValue(lan)
  //   } else {
  //     let langObj = []
  //     langid.forEach(element => {
  //       langObj.push({
  //         'name': element
  //       })
  //       languageId = langObj
  //       this.employeeFilterForm.controls['languageId'].setValue(languageId)
  //     });
  //   }

  //   let skills = data.filterByEmployeeResponseDTOList[0].skillId.split(",")
  //   let skillId = []
  //   let skill;
  //   if (skills.length == 1) {
  //     skills.forEach(element => {
  //       skill = [element]
  //     })
  //     this.employeeFilterForm.controls['skillId'].setValue(skill)
  //   } else {
  //     let skillObj = []
  //     skills.forEach(element => {
  //       skillObj.push({
  //         'name': element
  //       })
  //       skillId = skillObj
  //       this.employeeFilterForm.controls['skillId'].setValue(skillId)
  //     });
  //   }


  //   let edu = data.filterByEmployeeResponseDTOList[0].education.split(",")
  //   let education = []
  //   let ed;
  //   if (edu.length == 1) {
  //     edu.forEach(element => {
  //       ed = [element]
  //     })
  //     this.employeeFilterForm.controls['education'].setValue(ed)
  //   } else {
  //     let edObj = []
  //     edu.forEach(element => {
  //       edObj.push({
  //         'name': element
  //       })
  //       education = edObj
  //       this.employeeFilterForm.controls['education'].setValue(education)
  //     });
  //   }


  //   console.log(data)
  //   if (data.groupCompanyId == 0) {
  //     data.groupCompanyId = this.globalCompanyMasterIdList
  //   }

  //   this.companyData.forEach(element => {
  //     //console.log(parseInt(element.code))
  //     if (parseInt(data.groupCompanyId) == parseInt(element.code)) {
  //       this.companyMasterId = [element];
  //       console.log(this.companyMasterId)

  //       this.groupComapnyIds = this.companyMasterId[0].code
  //       let data = {
  //         groupCompanyIdList: [this.companyMasterId[0].code]
  //       }
  //       this.fixedData = []
  //       let tempdata = []
  //       let resArr = [];
  //       this.filtersService.getAllJobMasaterData(data).subscribe(res => {
  //         this.getAllJobMasters = res.data.results;
  //         this.getAllJobMasters.forEach(element => {
  //           this.jobMasterTypes.push(element.jobMasterType)
  //         });
  //         if (this.jobMasterTypes) {
  //           this.jobMasterTypes = [...new Set(this.jobMasterTypes)]
  //           for (let i = 0; i < this.jobMasterTypes.length; i++) {
  //             const result = this.getAllJobMasters.filter((o) => o.jobMasterType == this.jobMasterTypes[i])
  //             const set = new Set(result)
  //             set.forEach((o: any) => {
  //               if (this.fixedData.length == 0) {
  //                 tempdata.push
  //                   ({
  //                     active: o.active,
  //                     companyName: o.companyName,
  //                     fromDate: o.fromDate,
  //                     groupCompanyId: o.groupCompanyId,
  //                     jobMasterMappingId: o.jobMasterMappingId,
  //                     jobMasterType: o.jobMasterType,
  //                     jobMasterValueId: o.jobMasterValueId,
  //                     masterCode: o.masterCode,
  //                     masterDescription: o.masterDescription,
  //                     toDate: o.toDate
  //                   })
  //               } else {
  //                 tempdata.push
  //                   ({
  //                     active: o.active,
  //                     companyName: o.companyName,
  //                     fromDate: o.fromDate,
  //                     groupCompanyId: o.groupCompanyId,
  //                     jobMasterMappingId: o.jobMasterMappingId,
  //                     jobMasterType: o.jobMasterType,
  //                     jobMasterValueId: o.jobMasterValueId,
  //                     masterCode: o.masterCode,
  //                     masterDescription: o.masterDescription,
  //                     toDate: o.toDate
  //                   })
  //               }
  //               this.fixedData[i] =
  //                 ({
  //                   active: o.active,
  //                   companyName: o.companyName,
  //                  fromDate: o.fromDate,
  //                   groupCompanyId: o.groupCompanyId,
  //                   jobMasterMappingId: o.jobMasterMappingId,
  //                   jobMasterType: o.jobMasterType,
  //                   jobMasterValueId: o.jobMasterValueId,
  //                   toDate: o.toDate,
  //                 });
  //             });
  //           }
  //           tempdata.forEach(function (item) {
  //             var i = resArr.findIndex(x => x.jobMasterType == item.jobMasterType);
  //             if (i <= -1) {
  //               resArr.push(item);
  //             }
  //           });
  //           resArr?.forEach(ele => {
  //             ele.dropdownData = []
  //           })
  //           this.allData = tempdata
  //         }
  //         if (resArr.length > 0) {
  //           this.allData?.forEach((element, index) => {
  //             resArr?.forEach(ele => {
  //               if (element.jobMasterType == ele.jobMasterType) {
  //                 let ind = index;
  //                 ele.dropdownData.push({
  //                   name: element.masterCode,
  //                   code: element.jobMasterValueId,
  //                 })
  //               }
  //             })
  //           });
  //           this.jobMasterDropDown = resArr



  //         }
  //       })

  //       //Get Payrollarea Code
  //       let data1 = {
  //         groupCompanyIdList: [this.companyMasterId[0].code],
  //         "jobMasterType": "BusinessArea"
  //       }
  //       this.filtersService.getAllPayrollareacodeBycompanyids(data1).subscribe(res => {
  //         res.data.results[0].forEach(element => {
  //           const obj = {
  //             name: element.payrollAreaCode,
  //             code: element.id,
  //           };
  //           this.getAllPayrollareacodeBycompanyids.push(obj);
  //         })
  //       })
  //       this.filtersService.getEstablishmentDetails(this.companyMasterId[0].code).subscribe(res => {
  //         res.data.results[0].forEach(element => {
  //           const obj = {
  //             name: element.establishmentCode,
  //             code: element.establishmentMasterId,
  //           };
  //           this.getEstablishmentDetails.push(obj);
  //         })
  //       })
  //     }
  //   });


  //   setTimeout(() => {

  //     /*********************************** Set Establishment *******************/
  //     let establishment = data.filterByJobDetailsResponseDTOList[0].establishmentMasterId?.split(",")
  //     if (establishment.length == 1) {
  //       this.getEstablishmentDetails?.forEach(element => {
  //         if (element.code == parseInt(establishment[0])) {
  //           this.establishmentMasterId = [element]
  //         }
  //       });
  //     } else {
  //       let obj = []
  //       this.getEstablishmentDetails?.forEach(element => {
  //         establishment.forEach(ele => {
  //           if (element.code == ele) {
  //             obj.push(element)
  //             this.establishmentMasterId = obj
  //           }
  //         });
  //       });
  //     }

  //     /*********************************** Set payroll id *******************/
  //     let pay = data.filterByJobDetailsResponseDTOList[0].payrollAreaId.split(",");
  //     if (pay.length == 1) {
  //       this.filtersService.getPayrollAreaDetails().subscribe(res => {
  //         res.data.results.forEach(element => {
  //           if (parseInt(element.payrollAreaId) == parseInt(pay[0])) {
  //             this.payrollAreaCodeData.push(element.payrollAreaId)
  //             let obj = {
  //               name: element.payrollAreaCode,
  //               code: element.payrollAreaId
  //             }
  //             this.payrollAreaCode = [obj]
  //           }
  //        });
  //       })
  //     } else {
  //       this.filtersService.getPayrollAreaDetails().subscribe(res => {
  //         let obj = []
  //         res.data.results.forEach(element => {
  //           pay.forEach(payroll => {
  //             if (parseInt(element.payrollAreaId) == parseInt(payroll)) {
  //               this.payrollAreaCodeData.push(element.payrollAreaId)
  //               // this.payrollAreaCode.push({
  //               //   name: element.payrollAreaCode,
  //               //   code: element.payrollAreaId
  //               // })

  //               obj.push({
  //                 name: element.payrollAreaCode,
  //                 code: element.payrollAreaId
  //               })
  //               this.payrollAreaCode = obj
  //             }
  //           })
  //         });
  //       })
  //     }


  //     if (this.jobMasterDropDown.length > 0) {
  //       /***************** set job master data ******************/
  //       console.log(this.jobMasterDropDown)
  //       console.log(data.filterByJobDetailsResponseDTOList)
  //       let obj = []
  //       this.jobMasterDropDown.forEach(ele => {
  //         if (ele.jobMasterType == 'WorkLocation') {
  //           let workLocationMasterId = data.filterByJobDetailsResponseDTOList[0].workLocationMasterId.split(',')
  //           if (workLocationMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(workLocationMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             workLocationMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   obj.push(e)
  //                   ele.selectedValue = obj
  //                 }
  //               })
  //             });
              
  //           }
  //         }
  //         if (ele.jobMasterType == 'Department') {
  //           let objdept = []
  //           let departmentMasterId = data.filterByJobDetailsResponseDTOList[0].departmentMasterId.split(',')
  //           if (departmentMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(departmentMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             departmentMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objdept.push(e)
  //                   ele.selectedValue = objdept
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'CostCentre') {
  //           let objcost = []
  //           let costCentreId = data.filterByJobDetailsResponseDTOList[0].costCentreId.split(',')
  //           if (costCentreId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(costCentreId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //            costCentreId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objcost.push(e)
  //                   ele.selectedValue = objcost
  //                 }
  //               })
  //             }) 
  //           }
  //         }
  //         if (ele.jobMasterType == 'Grade') {
  //           let objgrade = []
  //           let gradeMasterId = data.filterByJobDetailsResponseDTOList[0].gradeMasterId.split(',')
  //           if (gradeMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(gradeMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             gradeMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objgrade.push(e)
  //                   ele.selectedValue = objgrade
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Designation1') {
  //           let objdesg1 = []
  //           let designation1MasterId = data.filterByJobDetailsResponseDTOList[0].designation1MasterId.split(',')
  //           if (designation1MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(designation1MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             designation1MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objdesg1.push(e)
  //                   ele.selectedValue = objdesg1
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'SubLocation') {
  //           let objsublocation = []
  //           let subLocationMasterId = data.filterByJobDetailsResponseDTOList[0].subLocationMasterId.split(',')
  //           if (subLocationMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(subLocationMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             subLocationMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objsublocation.push(e)
  //                   ele.selectedValue = objsublocation
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'BusinessArea') {
  //           let objBA = []
  //           let businessAreaMasterId = data.filterByJobDetailsResponseDTOList[0].businessAreaMasterId.split(',')
  //           if (businessAreaMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(businessAreaMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             businessAreaMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objBA.push(e)
  //                   ele.selectedValue = objBA
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'SubArea') {
  //           let objSubArea = []
  //           let subAreaId = data.filterByJobDetailsResponseDTOList[0].subAreaId.split(',')
  //           if (subAreaId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(subAreaId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             subAreaId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objSubArea.push(e)
  //                   ele.selectedValue = objSubArea
  //                 }
  //               })
  //             }) 
  //           }
  //         }
  //         if (ele.jobMasterType == 'Division') {
  //           let objDivision = []
  //           let divisionMasterId = data.filterByJobDetailsResponseDTOList[0].divisionMasterId.split(',')
  //           if (divisionMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(divisionMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             divisionMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objDivision.push(e)
  //                   ele.selectedValue = objDivision
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'SubDepartment') {
  //           let objSubDept = []
  //           let subDepartmentId = data.filterByJobDetailsResponseDTOList[0].subDepartmentId.split(',')
  //           if (subDepartmentId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(subDepartmentId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             subDepartmentId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objSubDept.push(e)
  //                   ele.selectedValue = objSubDept
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'SubCostCenter') {
  //           let objSubCost = []
  //          let subCostCenterId = data.filterByJobDetailsResponseDTOList[0].subCostCenterId.split(',')
  //           if (subCostCenterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(subCostCenterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             subCostCenterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objSubCost.push(e)
  //                   ele.selectedValue = objSubCost
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'ProfitCentre') {
  //           let objProfit = []
  //           let profitCentreMasterId = data.filterByJobDetailsResponseDTOList[0].profitCentreMasterId.split(',')
  //           if (profitCentreMasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(profitCentreMasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             profitCentreMasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objProfit.push(e)
  //                   ele.selectedValue = objProfit
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Designation2') {
  //           let objDesg2 = []
  //           let designation2MasterId = data.filterByJobDetailsResponseDTOList[0].designation2MasterId.split(',')
  //           if (designation2MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(designation2MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             designation2MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objDesg2.push(e)
  //                   ele.selectedValue = objDesg2
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Position1') {
  //           let objPos1 = []
  //           let position1MasterId = data.filterByJobDetailsResponseDTOList[0].position1MasterId.split(',')
  //           if (position1MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(position1MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             position1MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objPos1.push(e)
  //                   ele.selectedValue = objPos1
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Position2') {
  //           let objPos2 = []
  //           let position2MasterId = data.filterByJobDetailsResponseDTOList[0].position2MasterId.split(',')
  //           if (position2MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(position2MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             position2MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objPos2.push(e)
  //                   ele.selectedValue = objPos2
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Job1') {
  //           let objJob1 = []
  //           let job1MasterId = data.filterByJobDetailsResponseDTOList[0].job1MasterId.split(',')
  //           if (job1MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(job1MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             job1MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objJob1.push(e)
  //                   ele.selectedValue = objJob1
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Job2') {
  //           let objJob2 = []
  //           let job2MasterId = data.filterByJobDetailsResponseDTOList[0].job2MasterId.split(',')
  //           if (job2MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(job2MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             job2MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objJob2.push(e)
  //                   ele.selectedValue = objJob2
  //                 }
  //               })
  //             }) 
  //           }
  //         }
  //         if (ele.jobMasterType == 'Job3') {
  //           let objJob3 = []
  //           let job3MasterId = data.filterByJobDetailsResponseDTOList[0].job3MasterId.split(',')
  //           if (job3MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(job3MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             job3MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objJob3.push(e)
  //                   ele.selectedValue = objJob3
  //                 }
  //               })
  //             }) 
  //           }
  //         }
  //         if (ele.jobMasterType == 'Job4') {
  //           let objJob4 = []
  //           let job4MasterId = data.filterByJobDetailsResponseDTOList[0].job4MasterId.split(',')
  //           if (job4MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(job4MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             job4MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objJob4.push(e)
  //                   ele.selectedValue = objJob4
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Job5') {
  //           let objJob5 = []
  //           let job5MasterId = data.filterByJobDetailsResponseDTOList[0].job5MasterId.split(',')
  //           if (job5MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(job5MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             job5MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objJob5.push(e)
  //                   ele.selectedValue = objJob5
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Position3') {
  //           let objPos3 = []
  //           let position3MasterId = data.filterByJobDetailsResponseDTOList[0].position3MasterId.split(',')
  //           if (position3MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(position3MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             position3MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objPos3.push(e)
  //                   ele.selectedValue = objPos3
  //                 }
  //               })
  //             })  
  //           }
  //         }
  //         if (ele.jobMasterType == 'Position4') {
  //           let objPos4 = []
  //           let position4MasterId = data.filterByJobDetailsResponseDTOList[0].position4MasterId.split(',')
  //           if (position4MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(position4MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             position4MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objPos4.push(e)
  //                   ele.selectedValue = objPos4
  //                 }
  //               })
  //             }) 
  //           }
  //         }
  //         if (ele.jobMasterType == 'Position5') {
  //           let objPos5 = []
  //           let position5MasterId = data.filterByJobDetailsResponseDTOList[0].position5MasterId.split(',')
  //           if (position5MasterId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(position5MasterId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             position5MasterId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objPos5.push(e)
  //                   ele.selectedValue = objPos5
  //                 }
  //               })
  //             })
  //           }
  //         }
  //         if (ele.jobMasterType == 'StrategicBusinessUnit') {
  //           let objStratBussiness = []
  //           let strategicBusinessUnitId = data.filterByJobDetailsResponseDTOList[0].strategicBusinessUnitId.split(',')
  //           if (strategicBusinessUnitId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(strategicBusinessUnitId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             strategicBusinessUnitId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   objStratBussiness.push(e)
  //                   ele.selectedValue = objStratBussiness
  //                 }
  //               })
  //             })
  //           }
  //         }
  //         if (ele.jobMasterType == 'EmployeeType1') {
  //           let emptypeobj = []
  //           let employeeTypeId = data.filterByJobDetailsResponseDTOList[0].employeeTypeId.split(',')
  //           if (employeeTypeId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(employeeTypeId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             employeeTypeId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   emptypeobj.push(e)
  //                   ele.selectedValue = emptypeobj
  //                 }
  //               })
  //             })  
  //           }
  //         }

  //         if (ele.jobMasterType == 'empStatus') {
  //           let empstatusobj = []
  //           let empStatusId = data.filterByJobDetailsResponseDTOList[0].empStatusId.split(',')
  //           if (empStatusId.length == 1) {
  //             ele.dropdownData?.forEach(e => {
  //               if (e.code == parseInt(empStatusId)) {
  //                 ele.selectedValue = [e]
  //               }
  //             })
  //           } else {
  //             empStatusId.forEach(element => {
  //               ele.dropdownData?.forEach(e => {
  //                 if (e.code == parseInt(element)) {
  //                   empstatusobj.push(e)
  //                   ele.selectedValue = empstatusobj
  //                 }
  //               })
  //             })
  //           }
  //         }
  //       })
  //     }
  //   }, 2000);


  //   this.employeeFilterForm.disable()
  //   this.dateFilterForm.disable()
  //   this.jobDetailsFilterForm.disable()
  // }

    /** Edit All Filter Data */
    viewFilter(data) {
      console.log(data)
      this.isCurrentCycle = false;
      this.isCurrentWeek = false;
      this.isCurrentMonth = false;
      this.isCurrentQuarter = false;
      this.isCurrentBusinessYear = false;
      this.isCurrentCalendarYear = false;
      this.is30Days = false;
      this.is7Days = false;
      this.isToday = false;
  
      this.getAllPayrollareacodeBycompanyids = []
      this.getEstablishmentDetails = []
  
      this.establishmentMasterId = []
      this.payrollAreaCode = []
      this.payrollAreaCodeData = []
  
      this.editFilterFlag = false;
      this.viewFilterFlag = true;
      this.employeeFilterForm.disable()
      this.jobDetailsFilterForm.disable()
      this.dateFilterForm.disable()
      if(data.filterByEmployeeResponseDTOList?.length > 0){
        this.employeeFilterForm.patchValue(data.filterByEmployeeResponseDTOList[0])
      }
      // this.dateFilterForm.patchValue(data.filterByDateList[0])
      if(data.filterByJobDetailsResponseDTOList?.length > 0)
      {
        this.jobDetailsFilterForm.patchValue(data.filterByJobDetailsResponseDTOList[0])
      }
      this.filterName = data.name;
      this.filterMasterId = data.filterMasterId;
  
      /** By Date Filter */
      this.isCurrentCycle = data.filterByDateResponseDTOList[0]?.isCurrentCycle;
      this.isCurrentWeek = data.filterByDateResponseDTOList[0]?.isCurrentWeek;
      this.isCurrentMonth = data.filterByDateResponseDTOList[0]?.isCurrentMonth;
      this.isCurrentQuarter = data.filterByDateResponseDTOList[0]?.isCurrentQuarter;
      this.isCurrentBusinessYear = data.filterByDateResponseDTOList[0]?.isCurrentBusinessYear;
      this.isCurrentCalendarYear = data.filterByDateResponseDTOList[0]?.isCurrentCalendarYear;
      this.is30Days = data.filterByDateResponseDTOList[0]?.isLast30Days;
      this.is7Days = data.filterByDateResponseDTOList[0]?.isLast7Days;
      this.isToday = data.filterByDateResponseDTOList[0]?.isToday;
  
      if(data.filterByDateResponseDTOList[0]?.fromDate != '' && data.filterByDateResponseDTOList[0]?.endDate != ''){
        this.cycleSelectionOption = 'Custom Defined'
      }
  
      if(this.isCurrentCycle == true){
        this.cycleSelectionOption = 'Current Cycle'
      }
      if(this.isCurrentWeek == true){
        this.cycleSelectionOption = 'Current Week'
      }
      if(this.isCurrentMonth == true){
        this.cycleSelectionOption = 'Current Month'
      }
      if(this.isCurrentQuarter == true){
        this.cycleSelectionOption = 'Current Quarter'
      }
      if(this.isCurrentBusinessYear == true){
        this.cycleSelectionOption = 'Current Business Year'
      }
      if(this.isCurrentCalendarYear == true){
        this.cycleSelectionOption = 'Current Calender Year'
      }
      if(this.is30Days == true){
        this.cycleSelectionOption = 'Last 30 Days'
      }
      if(this.is7Days == true){
        this.cycleSelectionOption = 'Last 7 Days'
      }
      if(this.isToday == true){
        this.cycleSelectionOption = 'Today'
      }
      if(data.filterByDateResponseDTOList[0]?.fromCycle != ''){
        this.cycleSelectionOption = 'Cycle Range'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousCycle != 0){
        this.cycleSelectionOption = 'No. of Previous Cycle'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousWeeks != 0){
        this.cycleSelectionOption = 'No. of Previous Weeks'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousMonths != 0){
        this.cycleSelectionOption = 'No. of Previous Months'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousQuarters != 0){
        this.cycleSelectionOption = 'No. of Previous Quarters'
      }
      if(data.filterByDateResponseDTOList[0]?.noOfPreviousBusinessYear != 0){
        this.cycleSelectionOption = 'No. of Previous Business Years'
      }
     
      
      this.getcycleSelect(this.cycleSelectionOption)
      this.getCycleNameById(data.filterByDateResponseDTOList[0]?.cycleDefinitionId)
      setTimeout(() => {
        if(data.filterByDateResponseDTOList){
          data.filterByDateResponseDTOList[0].fromDate = new Date(data.filterByDateResponseDTOList[0]?.fromDate)
          data.filterByDateResponseDTOList[0].endDate = new Date(data.filterByDateResponseDTOList[0]?.endDate)
          this.dateFilterForm.patchValue(data.filterByDateResponseDTOList[0])
        }    
      }, 2000);
     
      /** By Employee Filter */
      let empAge = this.employeeFilterForm.controls['employeeAge'].value
      let serviceAge = this.employeeFilterForm.controls['serviceAge'].value
      empAge = empAge.split('-');
      // console.log(empAge)
      this.empFromAge = empAge[0]
      this.empToAge = empAge[1]
      serviceAge = serviceAge.split('-')
      this.serviceFromAge = serviceAge[0]
      this.serviceToAge = serviceAge[1];
  
      if(data.filterByEmployeeResponseDTOList){
        let langid = data.filterByEmployeeResponseDTOList[0]?.languageId.split(",")
        let languageId = []
        let lan;
        if (langid.length == 1) {
          langid.forEach(element => {
            lan = [element]
          })
          this.employeeFilterForm.controls['languageId'].setValue(lan)
        } else {
          let langObj = []
          langid.forEach(element => {
            langObj.push({
              'name': element
            })
            languageId = langObj
            this.employeeFilterForm.controls['languageId'].setValue(languageId)
          });
        }
    
        let skills = data.filterByEmployeeResponseDTOList[0].skillId.split(",")
        let skillId = []
        let skill;
        if (skills.length == 1) {
          skills.forEach(element => {
            skill = [element]
          })
          this.employeeFilterForm.controls['skillId'].setValue(skill)
        } else {
          let skillObj = []
          skills.forEach(element => {
            skillObj.push({
              'name': element
            })
            skillId = skillObj
            this.employeeFilterForm.controls['skillId'].setValue(skillId)
          });
        }
    
    
        let edu = data.filterByEmployeeResponseDTOList[0].education.split(",")
        let education = []
        let ed;
        if (edu.length == 1) {
          edu.forEach(element => {
            ed = [element]
          })
          this.employeeFilterForm.controls['education'].setValue(ed)
        } else {
          let edObj = []
          edu.forEach(element => {
            edObj.push({
              'name': element
            })
            education = edObj
            this.employeeFilterForm.controls['education'].setValue(education)
          });
        }
      }
     
  
  
      console.log(data)
      if (data.groupCompanyId == 0) {
        data.groupCompanyId = this.globalCompanyMasterIdList
      }
  
      this.companyData.forEach(element => {
        //console.log(parseInt(element.code))
        if (parseInt(data.groupCompanyId) == parseInt(element.code)) {
          this.companyMasterId = [element];
          console.log(this.companyMasterId)
  
          this.groupComapnyIds = this.companyMasterId[0].code
          let data = {
            groupCompanyIdList: [this.companyMasterId[0].code]
          }
          this.fixedData = []
          let tempdata = []
          let resArr = [];
          this.filtersService.getAllJobMasaterData(data).subscribe(res => {
            this.getAllJobMasters = res.data.results;
           this.getAllJobMasters.forEach(element => {
              this.jobMasterTypes.push(element.jobMasterType)
            });
            if (this.jobMasterTypes) {
              this.jobMasterTypes = [...new Set(this.jobMasterTypes)]
              for (let i = 0; i < this.jobMasterTypes.length; i++) {
                const result = this.getAllJobMasters.filter((o) => o.jobMasterType == this.jobMasterTypes[i])
                const set = new Set(result)
                set.forEach((o: any) => {
                  if (this.fixedData.length == 0) {
                    tempdata.push
                      ({
                        active: o.active,
                        companyName: o.companyName,
                        fromDate: o.fromDate,
                        groupCompanyId: o.groupCompanyId,
                        jobMasterMappingId: o.jobMasterMappingId,
                        jobMasterType: o.jobMasterType,
                        jobMasterValueId: o.jobMasterValueId,
                        masterCode: o.masterCode,
                        masterDescription: o.masterDescription,
                        toDate: o.toDate
                      })
                  } else {
                    tempdata.push
                      ({
                        active: o.active,
                        companyName: o.companyName,
                        fromDate: o.fromDate,
                        groupCompanyId: o.groupCompanyId,
                        jobMasterMappingId: o.jobMasterMappingId,
                        jobMasterType: o.jobMasterType,
                        jobMasterValueId: o.jobMasterValueId,
                        masterCode: o.masterCode,
                        masterDescription: o.masterDescription,
                        toDate: o.toDate
                      })
                  }
                  this.fixedData[i] =
                    ({
                      active: o.active,
                      companyName: o.companyName,
                      fromDate: o.fromDate,
                      groupCompanyId: o.groupCompanyId,
                      jobMasterMappingId: o.jobMasterMappingId,
                      jobMasterType: o.jobMasterType,
                      jobMasterValueId: o.jobMasterValueId,
                      toDate: o.toDate,
                    });
                });
              }
              tempdata.forEach(function (item) {
                var i = resArr.findIndex(x => x.jobMasterType == item.jobMasterType);
                if (i <= -1) {
                  resArr.push(item);
                }
              });
              resArr?.forEach(ele => {
                ele.dropdownData = []
              })
              this.allData = tempdata
            }
            if (resArr.length > 0) {
              this.allData?.forEach((element, index) => {
                resArr?.forEach(ele => {
                  if (element.jobMasterType == ele.jobMasterType) {
                    let ind = index;
                    ele.dropdownData.push({
                      name: element.masterCode,
                      code: element.jobMasterValueId,
                    })
                  }
                })
              });
              this.jobMasterDropDown = resArr
            }
          })
  
          //Get Payrollarea Code
          let data1 = {
            groupCompanyIdList: [this.companyMasterId[0].code],
            "jobMasterType": "BusinessArea"
          }
          this.filtersService.getAllPayrollareacodeBycompanyids(this.companyMasterId[0].code).subscribe(res => {
            res.data.results[0].forEach(element => {
              const obj = {
                name: element.payrollAreaCode,
                code: element.payrollAreaId,
              };
              this.getAllPayrollareacodeBycompanyids.push(obj);
            })
          })
          this.filtersService.getEstablishmentDetails(this.companyMasterId[0].code).subscribe(res => {
            res.data.results[0].forEach(element => {
              const obj = {
                name: element.establishmentCode,
                code: element.establishmentMasterId,
             };
              this.getEstablishmentDetails.push(obj);
            })
          })
        }
      });
  
  
      this.workLocationMasterIdData = []
      this.departmentMasterIdData = []
      this.costCentreIdData = []
      this.gradeMasterIdData = []
      this.designation1MasterIdData = []
      this.subLocationMasterIdData = []
      this.businessAreaMasterIdData = []
      this.subAreaIdData = []
      this.divisionMasterIdData = []
      this.subDepartmentIdData = []
      this.subCostCenterIdData = []
      this.profitCentreMasterIdData = []
      this.designation2MasterIdData = []
      this.position1MasterIdData = []
      this.position2MasterIdData = []
      this.job1MasterIdData = []
      this.job2MasterIdData = []
      this.job3MasterIdData = []
      this.job4MasterIdData = []
      this.position3MasterIdData = []
      this.position7MasterIdData = []
      this.position6MasterIdData = []
      this.employeeTypeIdData = []
      this.strategicBusinessUnitIdData = []
      this.job5MasterIdData = []
  
  
      setTimeout(() => {
  
        /*********************************** Set Establishment *******************/
        if(data.filterByJobDetailsResponseDTOList?.length > 0){
        let establishment = data.filterByJobDetailsResponseDTOList[0]?.establishmentMasterId?.split(",")
        if (establishment.length == 1) {
          this.getEstablishmentDetails?.forEach(element => {
            if (element.code == parseInt(establishment[0])) {
              this.establishmentMasterId = [element]
            }
          });
        } else {
          let obj = []
          this.getEstablishmentDetails?.forEach(element => {
            establishment.forEach(ele => {
              if (element.code == ele) {
                obj.push(element)
                this.establishmentMasterId = obj
              }
            });
          });
        }
  
        /*********************************** Set payroll id *******************/
        let pay = data.filterByJobDetailsResponseDTOList[0]?.payrollAreaId.split(",");
        if (pay.length == 1) {
          this.filtersService.getPayrollAreaDetails().subscribe(res => {
            res.data.results.forEach(element => {
              if (parseInt(element.payrollAreaId) == parseInt(pay[0])) {
                this.payrollAreaCodeData.push(element.payrollAreaId)
                let obj = {
                  name: element.payrollAreaCode,
                  code: element.payrollAreaId
                }
                this.payrollAreaCode = [obj]
              }
            });
          })
          
        } else {
          this.filtersService.getPayrollAreaDetails().subscribe(res => {
            let obj = []
            res.data.results.forEach(element => {
              pay.forEach(payroll => {
                if (parseInt(element.payrollAreaId) == parseInt(payroll)) {
                  this.payrollAreaCodeData.push(element.payrollAreaId)
                  // this.payrollAreaCode.push({
                  //   name: element.payrollAreaCode,
                  //   code: element.payrollAreaId
                  // })
  
                  obj.push({
                    name: element.payrollAreaCode,
                    code: element.payrollAreaId
                  })
                  this.payrollAreaCode = obj
                }
              })
            });
          })
        }
      }
        if (this.jobMasterDropDown.length > 0 && data.filterByJobDetailsResponseDTOList.length > 0) {
          /***************** set job master data ******************/
          console.log(this.jobMasterDropDown)
          console.log(data.filterByJobDetailsResponseDTOList)
          let obj = []
          this.jobMasterDropDown.forEach(ele => {
            if (ele.jobMasterType == 'WorkLocation') {
              let workLocationMasterId = data.filterByJobDetailsResponseDTOList[0]?.workLocationMasterId.split(',')
              if (workLocationMasterId.length == 1) {
                
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(workLocationMasterId)) {
                    this.workLocationMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                workLocationMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      obj.push(e)
                      this.workLocationMasterIdData.push(e.code)
                      ele.selectedValue = obj
                    }
                  })
                });
                
              }
            }
            if (ele.jobMasterType == 'Department') {
              let objdept = []
              let departmentMasterId = data.filterByJobDetailsResponseDTOList[0]?.departmentMasterId.split(',')
              if (departmentMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(departmentMasterId)) {
                    this.departmentMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                departmentMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objdept.push(e)
                      this.departmentMasterIdData.push(e.code)
                      ele.selectedValue = objdept
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'CostCentre') {
              let objcost = []
              let costCentreId = data.filterByJobDetailsResponseDTOList[0]?.costCentreId.split(',')
              if (costCentreId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(costCentreId)) {
                    this.costCentreIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                costCentreId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objcost.push(e)
                      this.costCentreIdData.push(e.code)
                      ele.selectedValue = objcost
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Grade') {
              let objgrade = []
              let gradeMasterId = data.filterByJobDetailsResponseDTOList[0]?.gradeMasterId.split(',')
              if (gradeMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(gradeMasterId)) {
                    this.gradeMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                gradeMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objgrade.push(e)
                      this.gradeMasterIdData.push(e.code)
                      ele.selectedValue = objgrade
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Designation1') {
              let objdesg1 = []
              let designation1MasterId = data.filterByJobDetailsResponseDTOList[0]?.designation1MasterId.split(',')
              if (designation1MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(designation1MasterId)) {
                    this.designation1MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                designation1MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objdesg1.push(e)
                      this.designation1MasterIdData.push(e.code)
                      ele.selectedValue = objdesg1
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubLocation') {
              let objsublocation = []
              let subLocationMasterId = data.filterByJobDetailsResponseDTOList[0]?.subLocationMasterId.split(',')
              if (subLocationMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subLocationMasterId)) {
                    this.subLocationMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subLocationMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objsublocation.push(e)
                      this.subLocationMasterIdData.push(e.code)
                      ele.selectedValue = objsublocation
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'BusinessArea') {
              let objBA = []
              let businessAreaMasterId = data.filterByJobDetailsResponseDTOList[0]?.businessAreaMasterId.split(',')
              if (businessAreaMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(businessAreaMasterId)) {
                    this.businessAreaMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                businessAreaMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objBA.push(e)
                      this.businessAreaMasterIdData.push(e.code)
                      ele.selectedValue = objBA
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubArea') {
              let objSubArea = []
              let subAreaId = data.filterByJobDetailsResponseDTOList[0]?.subAreaId.split(',')
              if (subAreaId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subAreaId)) {
                    this.subAreaIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subAreaId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objSubArea.push(e)
                      this.subAreaIdData.push(e.code)
                      ele.selectedValue = objSubArea
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Division') {
              let objDivision = []
              let divisionMasterId = data.filterByJobDetailsResponseDTOList[0]?.divisionMasterId.split(',')
              if (divisionMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(divisionMasterId)) {
                    this.divisionMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                divisionMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objDivision.push(e)
                      this.divisionMasterIdData.push(e.code)
                      ele.selectedValue = objDivision
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubDepartment') {
              let objSubDept = []
              let subDepartmentId = data.filterByJobDetailsResponseDTOList[0]?.subDepartmentId.split(',')
              if (subDepartmentId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subDepartmentId)) {
                    this.subDepartmentIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subDepartmentId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objSubDept.push(e)
                      this.subDepartmentIdData.push(e.code)
                      ele.selectedValue = objSubDept
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'SubCostCenter') {
              let objSubCost = []
              let subCostCenterId = data.filterByJobDetailsResponseDTOList[0]?.subCostCenterId.split(',')
              if (subCostCenterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(subCostCenterId)) {
                    this.subCostCenterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                subCostCenterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objSubCost.push(e)
                      this.subCostCenterIdData.push(e.code)
                      ele.selectedValue = objSubCost
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'ProfitCentre') {
              let objProfit = []
              let profitCentreMasterId = data.filterByJobDetailsResponseDTOList[0]?.profitCentreMasterId.split(',')
              if (profitCentreMasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(profitCentreMasterId)) {
                    this.profitCentreMasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                profitCentreMasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objProfit.push(e)
                      this.profitCentreMasterIdData.push(e.code)
                      ele.selectedValue = objProfit
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Designation2') {
              let objDesg2 = []
              let designation2MasterId = data.filterByJobDetailsResponseDTOList[0]?.designation2MasterId.split(',')
              if (designation2MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(designation2MasterId)) {
                    this.designation2MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                designation2MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objDesg2.push(e)
                      this.designation2MasterIdData.push(e.code)
                      ele.selectedValue = objDesg2
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position1') {
              let objPos1 = []
              let position1MasterId = data.filterByJobDetailsResponseDTOList[0]?.position1MasterId.split(',')
              if (position1MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position1MasterId)) {
                    this.position1MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position1MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos1.push(e)
                      this.position1MasterIdData.push(e.code)
                      ele.selectedValue = objPos1
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position2') {
              let objPos2 = []
              let position2MasterId = data.filterByJobDetailsResponseDTOList[0]?.position2MasterId.split(',')
              if (position2MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position2MasterId)) {
                    this.position2MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position2MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos2.push(e)
                      this.position2MasterIdData.push(e.code)
                      ele.selectedValue = objPos2
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Job1') {
              let objJob1 = []
              let job1MasterId = data.filterByJobDetailsResponseDTOList[0]?.job1MasterId.split(',')
              if (job1MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job1MasterId)) {
                    this.job1MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job1MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob1.push(e)
                      this.job1MasterIdData.push(e.code)
                      ele.selectedValue = objJob1
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Job2') {
              let objJob2 = []
              let job2MasterId = data.filterByJobDetailsResponseDTOList[0]?.job2MasterId.split(',')
              if (job2MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job2MasterId)) {
                    this.job2MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job2MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob2.push(e)
                      this.job2MasterIdData.push(e.code)
                      ele.selectedValue = objJob2
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Job3') {
              let objJob3 = []
              let job3MasterId = data.filterByJobDetailsResponseDTOList[0]?.job3MasterId.split(',')
              if (job3MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job3MasterId)) {
                    this.job3MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job3MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob3.push(e)
                      this.job3MasterIdData.push(e.code)
                      ele.selectedValue = objJob3
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Job4') {
              let objJob4 = []
              let job4MasterId = data.filterByJobDetailsResponseDTOList[0]?.job4MasterId.split(',')
              if (job4MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job4MasterId)) {
                    this.job4MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job4MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob4.push(e)
                      this.job4MasterIdData.push(e.code)
                      ele.selectedValue = objJob4
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Job5') {
              let objJob5 = []
              let job5MasterId = data.filterByJobDetailsResponseDTOList[0]?.job5MasterId.split(',')
              if (job5MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(job5MasterId)) {
                    this.job5MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                job5MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objJob5.push(e)
                      this.job5MasterIdData.push(e.code)
                      ele.selectedValue = objJob5
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position3') {
              let objPos3 = []
              let position3MasterId = data.filterByJobDetailsResponseDTOList[0]?.position3MasterId.split(',')
              if (position3MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position3MasterId)) {
                    this.position3MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position3MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos3.push(e)
                      this.position3MasterIdData.push(e.code)
                      ele.selectedValue = objPos3
                    }
                  })
                })  
              }
            }
            if (ele.jobMasterType == 'Position4') {
              let objPos4 = []
              let position4MasterId = data.filterByJobDetailsResponseDTOList[0]?.position4MasterId.split(',')
              if (position4MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position4MasterId)) {
                    this.position4MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position4MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos4.push(e)
                      this.position4MasterIdData.push(e.code)
                      ele.selectedValue = objPos4
                    }
                  })
                }) 
              }
            }
            if (ele.jobMasterType == 'Position5') {
              let objPos5 = []
              let position5MasterId = data.filterByJobDetailsResponseDTOList[0]?.position5MasterId.split(',')
              if (position5MasterId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(position5MasterId)) {
                    this.position5MasterIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                position5MasterId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objPos5.push(e)
                      this.position5MasterIdData.push(e.code)
                      ele.selectedValue = objPos5
                    }
                  })
                })
              }
            }
            if (ele.jobMasterType == 'StrategicBusinessUnit') {
              let objStratBussiness = []
              let strategicBusinessUnitId = data.filterByJobDetailsResponseDTOList[0]?.strategicBusinessUnitId.split(',')
              if (strategicBusinessUnitId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(strategicBusinessUnitId)) {
                    this.strategicBusinessUnitIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                strategicBusinessUnitId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      objStratBussiness.push(e)
                      this.strategicBusinessUnitIdData.push(e.code)
                      ele.selectedValue = objStratBussiness
                    }
                  })
                })
              }
            }
            if (ele.jobMasterType == 'EmployeeType1') {
              let emptypeobj = []
              let employeeTypeId = data.filterByJobDetailsResponseDTOList[0]?.employeeTypeId.split(',')
              if (employeeTypeId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(employeeTypeId)) {
                    ele.selectedValue = [e]
                    this.employeeTypeIdData.push(e.code)
                  }
                })
              } else {
                employeeTypeId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      emptypeobj.push(e)
                      this.employeeTypeIdData.push(e.code)
                      ele.selectedValue = emptypeobj
                    }
                  })
                })  
              }
            }
  
            if (ele.jobMasterType == 'empStatus') {
              let empstatusobj = []
              let empStatusId = data.filterByJobDetailsResponseDTOList[0]?.empStatusId.split(',')
              if (empStatusId.length == 1) {
                ele.dropdownData?.forEach(e => {
                  if (e.code == parseInt(empStatusId)) {
                    this.empStatusIdData.push(e.code)
                    ele.selectedValue = [e]
                  }
                })
              } else {
                empStatusId.forEach(element => {
                  ele.dropdownData?.forEach(e => {
                    if (e.code == parseInt(element)) {
                      empstatusobj.push(e)
                      this.empStatusIdData.push(e.code)
                      ele.selectedValue = empstatusobj
                    }
                  })
                })
              }
            }
          })
        }
      }, 2000);
  
  
      this.employeeFilterForm.enable()
      this.dateFilterForm.enable()
      this.jobDetailsFilterForm.enable()
    }

  /**Delete Filter Data */
  deletepopup(deleteTemp: TemplateRef<any>, data) {
    this.modalRef = this.modalService.show(
      deleteTemp,
      Object.assign({}, {
        class: 'gray modal-md'
      })
    );
    this.deleteFilterMasterId = data.filterMasterId;
  }

  deleteFilterData() {
    this.filtersService.deleteFilterData(this.deleteFilterMasterId).subscribe(res => {
      this.alerservice.sweetalertMasterSuccess('Filter Master Deleted Successfully', '');
      this.deleteFilterMasterId = ''
      this.getAllFiltersSummary();
      this.resetForms();
    })
  }

  getcycleSelect(evt) {
    this.noOfCalculationFlag = false;
    this.showCycleDefinitionFlag = false;
    this.showFromAndToCycles = false;
    this.showCylces = false;
    this.selectedCyclerange = evt;
    this.cycleSelectionOption = evt;

    this.isFilterByDate = true

    if(evt == ''){
      this.isFilterByDate = false
    }

    this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(false)
    this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(false)
    this.dateFilterForm.controls['isCurrentCycle'].setValue(false)
    this.dateFilterForm.controls['isCurrentMonth'].setValue(false)
    this.dateFilterForm.controls['isCurrentQuarter'].setValue(false)
    this.dateFilterForm.controls['isCurrentWeek'].setValue(false)
    this.dateFilterForm.controls['isCyclewise'].setValue(false)
    this.dateFilterForm.controls['isLast7Days'].setValue(false)
    this.dateFilterForm.controls['isLast30Days'].setValue(false)
    this.dateFilterForm.controls['isSummation'].setValue(false)
    this.dateFilterForm.controls['isToday'].setValue(false)
    this.dateFilterForm.controls['noOfPreviousBusinessYear'].setValue(0)
    this.dateFilterForm.controls['noOfPreviousCycle'].setValue(0)
    this.dateFilterForm.controls['noOfPreviousMonths'].setValue(0)
    this.dateFilterForm.controls['noOfPreviousQuarters'].setValue(0)
    this.dateFilterForm.controls['noOfPreviousWeeks'].setValue(0)
    this.dateFilterForm.controls['periodDefinition'].setValue(0)
    this.dateFilterForm.controls['fromCycle'].setValue('')
    this.dateFilterForm.controls['toCycle'].setValue('')
    if(this.editFilterFlag == false){
      this.dateFilterForm.controls['fromDate'].setValue('')
      this.dateFilterForm.controls['endDate'].setValue('')
    }  
    this.dateFilterForm.controls['cycleDefinitionId'].setValue('')

    this.dateFilterForm.controls['fromDate'].enable()
      this.dateFilterForm.controls['endDate'].enable()

    if (evt == 'Custom Defined') {
      this.dateFilterForm.controls['fromDate'].enable()
      this.dateFilterForm.controls['endDate'].enable()
      // this.dateFilterForm.controls['fromDate'].reset()
      // this.dateFilterForm.controls['endDate'].reset()
    }
    if (evt == 'Current Cycle') {
      this.showCycleDefinitionFlag = true;
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
      this.dateFilterForm.controls['isCurrentCycle'].setValue(true)
    }
    if (evt == 'Current Week') {
      var curr = new Date; // get current date
      var first = curr.getDate() - curr.getDay();
      var firstday = new Date(curr.setDate(first))
      this.dateFilterForm.controls['fromDate'].setValue(firstday)
      var date = new Date(firstday);
      date.setDate(date.getDate() + 6);
      this.dateFilterForm.controls['endDate'].setValue(date)
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['isCurrentWeek'].setValue(true)
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
    }
    if (evt == 'Current Month') {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateFilterForm.controls['fromDate'].setValue(firstDay)
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateFilterForm.controls['endDate'].setValue(lastDay)
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['isCurrentMonth'].setValue(true)
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
    }
    if (evt == 'Current Quarter') {
      var curr = new Date; // get current date
      var first = curr.getMonth() - 3;
      var firstday = new Date(curr.setMonth(first))
      this.dateFilterForm.controls['fromDate'].setValue(firstday)
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['isCurrentQuarter'].setValue(true)
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
    }
    if (evt == 'Current Business Year') {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      this.dateFilterForm.controls['fromDate'].setValue(firstDay)
      var lastDay = new Date(date.getFullYear(), 11, 31);
      this.dateFilterForm.controls['endDate'].setValue(lastDay)
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
      this.dateFilterForm.controls['isCurrentBusinessYear'].setValue(true)
    }
    if (evt == 'Current Calender Year') {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      this.dateFilterForm.controls['fromDate'].setValue(firstDay)
      var lastDay = new Date(date.getFullYear(), 11, 31);
      this.dateFilterForm.controls['endDate'].setValue(lastDay)
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
      this.dateFilterForm.controls['isCurrentCalendarYear'].setValue(true)
    }
    if (evt == 'Cycle Range') {
      this.showCycleDefinitionFlag = true;
      this.showFromAndToCycles = true;
    }
    if (evt == 'Last 30 Days') {
      var date = new Date();
      date.setDate(date.getDate() - 30);
      this.dateFilterForm.controls['fromDate'].setValue(date)
      this.dateFilterForm.controls['endDate'].setValue(new Date())
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['isLast30Days'].setValue(true)
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
    }
    if (evt == 'Last 7 Days') {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      this.dateFilterForm.controls['fromDate'].setValue(date)
      this.dateFilterForm.controls['endDate'].setValue(new Date())
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['isLast7Days'].setValue(true)
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
    }
    if (evt == 'Today') {
      this.dateFilterForm.controls['fromDate'].setValue(new Date())
      this.dateFilterForm.controls['endDate'].setValue(new Date())
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['isToday'].setValue(true)
      this.dateFilterForm.controls['fromDate'].disable()
      this.dateFilterForm.controls['endDate'].disable()
    }
    
    if (evt == 'No. of Previous Cycle') {
      this.noOfCalculationFlag = true;
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].setValue('')
      this.dateFilterForm.controls['endDate'].setValue('')
    }
    if (evt == 'No. of Previous Weeks') {
      this.noOfCalculationFlag = true;
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].setValue('')
      this.dateFilterForm.controls['endDate'].setValue('')
    }
    if (evt == 'No. of Previous Months') {
      this.noOfCalculationFlag = true;
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].setValue('')
      this.dateFilterForm.controls['endDate'].setValue('')
    }
    if (evt == 'No. of Previous Quarters') {
      this.noOfCalculationFlag = true;
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].setValue('')
      this.dateFilterForm.controls['endDate'].setValue('')
    }
    if (evt == 'No. of Previous Business Years') {
      this.noOfCalculationFlag = true;
      this.showCycleDefinitionFlag = false;
      this.dateFilterForm.controls['fromDate'].setValue('')
      this.dateFilterForm.controls['endDate'].setValue('')

    }
    // if (evt == 'No. of Previous Calender Years') {
    //   this.noOfCalculationFlag = true;
    //   this.showCycleDefinitionFlag = false;
    // }
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  getNoOfCalculation(evt) {
    if (this.cycleSelectionOption == 'No. of Previous Weeks') {
      var curr = new Date; // get current date
      var first = curr.getDay() - 7;
      var firstday = new Date(curr.setDate(first))
      //this.dateFilterForm.controls['fromDate'].setValue(firstday)
    }
    if (this.cycleSelectionOption == 'No. of Previous Months') {
      var date = new Date();
      let finaldate = this.subtractMonths(date, evt)
     // this.dateFilterForm.controls['fromDate'].setValue(finaldate)
    }
    if (this.cycleSelectionOption == 'No. of Previous Quarters') {
      var date = new Date();
      let quarter = evt * 3
      let finaldate = this.subtractMonths(date, quarter)
    //  this.dateFilterForm.controls['fromDate'].setValue(finaldate)
    }
    if (this.cycleSelectionOption == 'No. of Previous Cycle') {
    }
    if (this.cycleSelectionOption == 'No. of Previous Business Years') {
    }
    if (this.cycleSelectionOption == 'No. of Previous Calender Years') {
    }
  }

  /** Get All Cycle Definition */
  getAllCycleDefinition() {
    this.cycleDefifitionList = []
    this.filtersService.getAllCycleDefinition().subscribe((res) => {
      this.cycleDefinition = res.data.results[0];
      res.data.results[0].forEach((element) => {
        const obj = {
          label: element.name,
          value: element.id
        };
        this.cycleDefifitionList.push(obj);
      });
    });
  }

  /** Get Cycle Name By Id */
  getCycleNameById(id) {
    this.showCylces = true
    if (this.selectedCyclerange == 'Cycle Range') {
      this.showCylces = false
    }
    this.businessCycleDefinationId = id
    this.cycleNameList = [];
    this.cycleDefifitionList.forEach(ele => {
      if (ele.id == id) {
        this.selctedCycleName = ele.cycleName
      }
    })
    this.dateFilterForm.controls['periodDefinition'].setValue('0')
    this.dateFilterForm.controls['fromDate'].setValue('')
    this.dateFilterForm.controls['endDate'].setValue('')

    if(this.cycleSelectionOption == 'Current Cycle'){
      this.filtersService.getCurrentCycleNameById(id).subscribe((response) => {
        this.cycleNameList = response.data.results;
        this.dateFilterForm.controls['periodDefinition'].setValue(response.data.results[0].id)
        this.dateFilterForm.controls['fromDate'].setValue(new Date(response.data.results[0].fromDate))
        this.dateFilterForm.controls['endDate'].setValue(new Date(response.data.results[0].toDate))
      });
      console.log( this.dateFilterForm.value)
    }else{
      this.filtersService.getCycleNameById(id).subscribe((response) => {
        this.cycleNameList = response.data.results;
      });
    }
   
  }

  /** on Change Cycle Name get the From date and to Date */
  onSelectCycleName(evt) {
    this.dateFilterForm.patchValue({
      fromDate: '',
      endDate: '',
    });

    this.cycleNameList.forEach(ele =>{
      if(ele.id == evt){
        console.log(ele)

        this.dateFilterForm.patchValue({
          fromDate: new Date(ele.fromDate),
          endDate: new Date(ele.toDate),
        });
      }
    })
  }

  onFromSelectCycleName(evt){
    this.dateFilterForm.patchValue({
      fromDate: '',
      endDate: '',
    });

    // this.cycleNameList.forEach(ele =>{
    //   if(ele.id == evt){
    //     this.dateFilterForm.patchValue({
    //       fromDate: new Date(ele.fromDate),
    //       endDate: new Date(ele.toDate),
    //     });
    //   }
    // })
  }

  onToSelectCycleName(evt){
    this.dateFilterForm.patchValue({
      fromDate: '',
      endDate: '',
    });

    // this.cycleNameList.forEach(ele =>{
    //   if(ele.id == evt){
    //     this.dateFilterForm.patchValue({
    //       fromDate: new Date(ele.fromDate),
    //       endDate: new Date(ele.toDate),
    //     });
    //   }
    // })
  }

  /** Get All Period Definition List */
  getAllPeriodDefnList(frequencyid) {
    this.dateFilterForm.controls['cycleDefinitionId'].setValue(null)
    if (frequencyid > 0) {
      if (frequencyid == 8 || frequencyid == 2 || frequencyid == 3 ||
        frequencyid == 4 || frequencyid == 5) {
        this.dateFilterForm.controls['cycleDefinitionId'].disable()
      } else {
        this.dateFilterForm.controls['cycleDefinitionId'].enable()
        this.filtersService.getAllPeriodDefnList(frequencyid).subscribe((res) => {
          this.periodDefList = res.data.results[0];
        },
          (err) => {
            this.alertService.sweetalertError(err.error.status.message)
          }
        );
      }
    }
  }

  /** Employee Checkbox Click popup aakansha - Aakansha 7 March 22*/
  Emplist(event, emplist: TemplateRef<any>) {
    if (event.checked) {
      this.employeeFilterForm.patchValue({
        employeeCode: ''
      });
      this.modalRef = this.modalService.show(
        emplist,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    } else {
      this.employeeFilterForm.get('employeeCode').enable();
      this.employeeFilterForm.get('employeeSet').enable();
    }
  }


  employeeListCopyPaste(event, pasteEmployee: TemplateRef<any>) {
    if (event.checked) {
      this.modalRef = this.modalService.show(
        pasteEmployee,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    }
  }

  employeeListPasteData(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let row_data = pastedText.split('\n');
    this.displayedColumns = ["EmpCode"]
    this.employeeList = [];
    for (let i = 0; i < row_data.length; i++) {
      let data = row_data[i].replace('\r', '');
      this.employeeList.push(data)
    }
    let lastIndex = this.employeeList.length - 1
    this.employeeList.splice(lastIndex, 1)
    console.log("paste: ", this.employeeList)
  }

  exportExcel() {
    this.excelData = [];
    this.header = []
    this.header = ["Filter Name"];
    this.allFilterSummary.forEach(element => {
      let obj = {
        "Filter Name": element.name,
      }
      this.excelData.push(obj)
    });
    this.excelservice.exportAsExcelFile(this.excelData, 'Filter Master Summary', 'Filter Master Summary', this.header);
  }


}